import errorHandlerService from "@/services/error-handler.service";
import { AxiosError } from "axios";
import { Commit, Module } from "vuex";
import { RootState } from ".";

export type StoreApiData<T> = {
  isLoading: boolean;
  data?: T;
  error?: AxiosError;
};

export const createApiModule = <T>(
  id: string,
  creator: (data: {
    baseModule: Module<any, any>;
    apiMutations: {
      loadStart: string;
      loadSuccess: string;
      loadError: string;
    };
    apiDataActionHelper: (
      storeKey: string,
      commit: Commit,
      getter: () => any
    ) => void;
  }) => Module<T, RootState>
): Module<T, RootState> => {
  const apiMutations = {
    loadStart: `${id}/loadStart`,
    loadSuccess: `${id}/loadSuccess`,
    loadError: `${id}/loadError`,
  };
  const apiDataActionHelper = async (
    key: string,
    commit: Commit,
    getter: () => any
  ) => {
    commit(apiMutations.loadStart, { key });
    try {
      const data = await getter();
      commit(apiMutations.loadSuccess, { key, data });
    } catch (error: any) {
      errorHandlerService.handleApiError(error);
      commit(apiMutations.loadError, { key, error });
      throw error;
    }
  };
  return creator({
    apiMutations,
    apiDataActionHelper,
    baseModule: {
      mutations: {
        [apiMutations.loadStart](state, { key }: { key: string }) {
          const currentItem = state[key];
          const dataToSet: StoreApiData<any> = {
            isLoading: true,
            error: undefined,
            data: currentItem?.data,
          };
          state[key] = dataToSet;
        },
        [apiMutations.loadSuccess](
          state,
          { key, data }: { key: string; data: any }
        ) {
          const dataToSet: StoreApiData<any> = {
            isLoading: false,
            error: undefined,
            data,
          };
          state[key] = dataToSet;
        },
        [apiMutations.loadError](
          state,
          { key, error }: { key: string; error: any }
        ) {
          const currentItem = state[key];
          const dataToSet: StoreApiData<any> = {
            isLoading: false,
            error,
            data: currentItem?.data,
          };
          state[key] = dataToSet;
        },
      },
    },
  });
};
