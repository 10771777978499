import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import LoginPage from "@/views/Login.vue";
import TabsPage from "@/views/Tabs.vue";
import OnboardingPage from "@/views/Onboarding.vue";
import LoginLinkSentPage from "@/views/LoginLinkSent.vue";
import HomePage from "@/views/Home.vue";
import DealsPage from "@/views/Deals.vue";
import RevenuePage from "@/views/Revenue.vue";
import ServicesPage from "@/views/Services.vue";
import SettingsPage from "@/views/Settings.vue";
import { actionTypes, store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Onboarding",
    component: OnboardingPage,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/login-link-sent",
    name: "LoginLinkSent",
    component: LoginLinkSentPage,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        name: "HomePage",
        component: HomePage,
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "home/pending-invoices",
        name: "PendingInvoices",
        component: () => import("@/views/PendingInvoices.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "deals",
        name: "DealsPage",
        component: DealsPage,
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "deals/details/:id",
        name: "DealsDetails",
        component: () => import("@/views/DealDetails.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "deals/details/:id/:calendarEventId",
        name: "DealsDetailsEvent",
        component: () => import("@/views/DealDetails.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "revenue",
        name: "RevenuePage",
        component: RevenuePage,
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "services",
        name: "ServicesPage",
        component: ServicesPage,
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings",
        name: "SettingsPage",
        component: SettingsPage,
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/personal-profile",
        component: () => import("@/views/PersonalProfile.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/company-profile",
        component: () => import("@/views/CompanyProfile.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/notifications",
        component: () => import("@/views/NotificationsSettings.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/language",
        component: () => import("@/views/LanguageSettings.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/external-accounts/:type",
        component: () => import("@/views/ExternalAccounts.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/external-accounts/:type/add",
        component: () => import("@/views/AddExternalAccount.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/assistants",
        component: () => import("@/views/Assistants.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
      {
        path: "settings/assistants/add",
        component: () => import("@/views/AddAssistant.vue"),
        meta: {
          authenticatedOnly: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isInitialized = store.state.user.isInitialized;
  if (!isInitialized) {
    await store.dispatch(actionTypes.user.init);
  }
  const isAuthenticated = store.state.user.isAuthenticated;
  if (isAuthenticated && to.matched.some((record) => record.meta.guestOnly)) {
    next({
      name: "HomePage",
    });
  } else if (
    !isAuthenticated &&
    to.matched.some((record) => record.meta.authenticatedOnly)
  ) {
    next({
      name: "Login",
    });
  } else {
    next();
  }
});

export default router;
