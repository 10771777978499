import { storeKey } from "@/store";
import { StoreApiData } from "@/store/generic";
import { computed, watchEffect } from "vue";
import { useStore } from "vuex";

export const createApiDataHook = <T>(
  dataPath: string[],
  actionType: string,
  authRequired = true
) => {
  return () => {
    const { state, dispatch } = useStore(storeKey);
    const dataRef = computed<StoreApiData<T>>(
      () => (state as any)[dataPath[0]][dataPath[1]]
    );
    watchEffect(() => {
      const dataItem = (state as any)[dataPath[0]][dataPath[1]];
      if (
        (authRequired && !state.user.isAuthenticated) ||
        dataItem?.isLoading ||
        dataItem?.data !== undefined ||
        dataItem?.error
      ) {
        return;
      }
      dispatch(actionType);
    });
    return dataRef;
  };
};
