<template>
  <div :class="['button', 'button--' + fill, 'ion-activatable', className]">
    <IonRippleEffect />
    <div class="text">
      <slot />
    </div>
    <div v-if="rightIcon" class="icon-right">
      <font-awesome-icon :icon="rightIcon" />
    </div>
  </div>
</template>

<script lang="ts">
import { IonRippleEffect } from "@ionic/vue";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "button-component",
  components: {
    IonRippleEffect,
  },
  props: {
    fill: {
      type: String as PropType<"solid" | "outline">,
      default: "solid",
    },
    rightIcon: {
      type: Array as PropType<string[]>,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
  },
});
</script>

<style scoped>
.button {
  height: 58px;
  background: var(--loopin-gradient);
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.button--solid {
}
.button--outline::after {
  position: absolute;
  content: " ";
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 12px;
  background-color: var(--ion-background-color);
  z-index: -1;
}
.text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  z-index: 2;
}
.icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  font-size: 22px;
  z-index: 2;
}
</style>