import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faHouse } from "@fortawesome/free-solid-svg-icons/faHouse";
import { faChampagneGlasses } from "@fortawesome/free-solid-svg-icons/faChampagneGlasses";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faBellConcierge } from "@fortawesome/free-solid-svg-icons/faBellConcierge";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faMessage } from "@fortawesome/free-solid-svg-icons/faMessage";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faLeaf } from "@fortawesome/free-solid-svg-icons/faLeaf";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons/faCommentsDollar";
import { faSignOut } from "@fortawesome/free-solid-svg-icons/faSignOut";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCamera } from "@fortawesome/free-solid-svg-icons/faCamera";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileLines } from "@fortawesome/free-solid-svg-icons/faFileLines";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons/faCirclePlay";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faMusic } from "@fortawesome/free-solid-svg-icons/faMusic";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons/faNewspaper";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faScissors } from "@fortawesome/free-solid-svg-icons/faScissors";
import { faScaleBalanced } from "@fortawesome/free-solid-svg-icons/faScaleBalanced";
import { faFileContract } from "@fortawesome/free-solid-svg-icons/faFileContract";
import { faUserNurse } from "@fortawesome/free-solid-svg-icons/faUserNurse";
import { faArrowUpRightDots } from "@fortawesome/free-solid-svg-icons/faArrowUpRightDots";

library.add(faChevronUp);
library.add(faChevronDown);
library.add(faChevronRight);
library.add(faHouse);
library.add(faChampagneGlasses);
library.add(faChartLine);
library.add(faBellConcierge);
library.add(faGear);
library.add(faCalendar);
library.add(faMessage);
library.add(faTimes);
library.add(faLeaf);
library.add(faSearch);
library.add(faArrowLeft);
library.add(faCommentsDollar);
library.add(faSignOut);
library.add(faPlus);
library.add(faCamera);
library.add(faFile);
library.add(faCirclePlay);
library.add(faImage);
library.add(faMusic);
library.add(faArrowRight);
library.add(faCircleXmark);
library.add(faTriangleExclamation);
library.add(faQuestion);
library.add(faNewspaper);
library.add(faFileLines);
library.add(faVideo);
library.add(faTrash);
library.add(faCheck);
library.add(faScissors);
library.add(faScaleBalanced);
library.add(faFileContract);
library.add(faUserNurse);
library.add(faArrowUpRightDots);
