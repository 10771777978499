<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  toastController,
  useIonRouter,
} from "@ionic/vue";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { actionTypes, storeKey } from "./store";
import errorHandlerService from "./services/error-handler.service";
import { Browser } from "@capacitor/browser";
import { getQueryParameterByName } from "./utils";
import { useI18n } from "vue-i18n";
import userService from "./services/user.service";
import { SplashScreen } from "@capacitor/splash-screen";
import notificationsService from "./services/notifications.service";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    const router = useIonRouter();
    const store = useStore(storeKey);
    const { t } = useI18n();
    setTimeout(() => {
      SplashScreen.hide();
    }, 500);
    notificationsService.onNavigationCallback = (url) => {
      router.navigate(url, "root", "replace");
    };
    if (isPlatform("capacitor")) {
      App.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
        if (event.url.indexOf("loopin:///signin") === 0) {
          if (store.state.user.isAuthenticated) {
            return;
          }
          try {
            await store.dispatch(
              actionTypes.user.loginWithMagicLink,
              event.url.split("#")[1]
            );
            router.navigate("/tabs/home", "root");
          } catch (error: any) {
            errorHandlerService.handleApiError(error);
          }
        } else if (
          event.url.indexOf("loopin:///external_account_redirect") === 0
        ) {
          Browser.close();
          const success = getQueryParameterByName("success", event.url);
          if (success === "1") {
            (
              await toastController.create({
                message: t("add-social-account-page.external-account-added"),
                position: "top",
                duration: 1000,
                color: "primary",
              })
            ).present();
            if (userService.connectExternalAccountSuccess) {
              userService.connectExternalAccountSuccess();
            }
          } else if (success === "0") {
            const message = getQueryParameterByName("message", event.url);
            errorHandlerService.handleError({ message: message || undefined });
            if (userService.connectExternalAccountError) {
              userService.connectExternalAccountError();
            }
          }
        }
      });
    } else {
      if (
        window.location.pathname.includes("/signin") &&
        window.location.hash
      ) {
        store
          .dispatch(
            actionTypes.user.loginWithMagicLink,
            window.location.hash.slice(1)
          )
          .then(() => {
            router.navigate("/tabs/home", "root");
          });
      }
    }
  },
});
</script>