<template>
  <ion-page>
    <ion-content class="content">
      <div
        class="content-inner"
        :style="{
          height: `calc(100% - ${keyBoardHeight || 0}px)`,
        }"
      >
        <div
          :class="{
            wrap: true,
            'wrap--keyboard-open': keyboardOpen,
            'wrap--keyboard-open-android': isAndroid,
          }"
        >
          <img
            :src="require('@/assets/images/magic-trick-dynamic-color.png')"
          />
          <div class="title">{{ t("login-page.title") }}</div>
          <div class="subtitle">
            {{ t("login-page.no-more-passwords-subtitle") }}
            <br />
            {{ t("login-page.get-magic-link-subtitle") }}
          </div>
          <div class="input-wrap">
            <input
              class="loopin-input"
              placeholder="Email"
              inputmode="email"
              v-model="form.email"
            />
          </div>
          <div class="input-wrap" v-if="!magicLinkLogin">
            <input
              class="loopin-input"
              placeholder="Password"
              type="password"
              v-model="form.password"
            />
          </div>
          <ion-button
            mode="ios"
            class="loopin-button loopin-button--gradient"
            size="large"
            expand="block"
            :disabled="loginInProgress"
            @click="login"
          >
            <ion-spinner v-if="loginInProgress" name="lines"></ion-spinner>
            <template v-else
              >{{ t("login-page.receive-magic-link") }}
            </template>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonButton,
  IonSpinner,
  onIonViewWillEnter,
  onIonViewDidLeave,
  isPlatform,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import errorHandlerService from "@/services/error-handler.service";
import userService from "@/services/user.service";
import { Keyboard, KeyboardInfo, KeyboardResize } from "@capacitor/keyboard";
import { useStore } from "vuex";
import { storeKey } from "@/store";
import { actionTypes } from "@/store";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginPage",
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonSpinner,
  },
  setup() {
    const keyBoardHeight = ref<number | null>(0);
    const router = useRouter();
    const store = useStore(storeKey);
    const loginInProgress = ref<boolean>(false);
    const form = ref({
      email: "",
      password: "",
    });
    const keyboardOpen = ref(false);
    const { t } = useI18n();
    // TODO: left for debug purposes
    // enabled username/password login when 'false'
    const magicLinkLogin = true;
    if (isPlatform("capacitor")) {
      const keyboardWillShowHandler = (info: KeyboardInfo) => {
        if (isPlatform("ios")) {
          keyBoardHeight.value = info.keyboardHeight;
        }
        keyboardOpen.value = true;
      };
      const keyboardWillHideHandler = () => {
        if (isPlatform("ios")) {
          keyBoardHeight.value = 0;
        }
        keyboardOpen.value = false;
      };
      onIonViewWillEnter(() => {
        Keyboard.setResizeMode({
          mode: KeyboardResize.None,
        });
        Keyboard.addListener("keyboardWillShow", keyboardWillShowHandler);
        Keyboard.addListener("keyboardWillHide", keyboardWillHideHandler);
      });
      onIonViewDidLeave(() => {
        Keyboard.setResizeMode({
          mode: KeyboardResize.Native,
        });
        Keyboard.removeAllListeners();
      });
    }
    const login = async () => {
      try {
        loginInProgress.value = true;
        if (magicLinkLogin) {
          const linkSent = await userService.sendMagicLink(form.value.email);
          if (!linkSent) {
            return errorHandlerService.handleError({
              message: t("error-messages.email-not-exist"),
            });
          }
          if (isPlatform("capacitor")) {
            Keyboard.hide();
          }
          router.push("/login-link-sent");
          setTimeout(() => {
            loginInProgress.value = false;
          }, 1000);
        } else {
          await store.dispatch(actionTypes.user.login, form.value);
          router.push("/tabs/home");
        }
      } catch (error: any) {
        errorHandlerService.handleApiError(error);
      } finally {
        loginInProgress.value = false;
      }
    };
    return {
      t,
      keyboardOpen,
      form,
      login,
      loginInProgress,
      keyBoardHeight,
      isAndroid: isPlatform("android"),
      magicLinkLogin,
    };
  },
});
</script>

<style scoped>
.content {
  --keyboard-offset: 0 !important;
  --overflow: hidden;
}
.content-inner {
  transition: height 0.3s;
  transform: translate3d(0, 0, 0);
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.wrap {
  padding: 0 40px;
  text-align: center;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.wrap--keyboard-open {
  height: auto;
  min-height: 100%;
  padding-bottom: 30px;
}
.wrap--keyboard-open.wrap--keyboard-open-android {
  min-height: initial;
}
.wrap img {
  width: 100%;
  max-width: 200px;
  margin: 0 auto 22px auto;
}
.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
  margin-bottom: 24px;
}
.subtitle {
  font-size: 26px;
  line-height: 25px;
  color: var(--loopin-color-gs-60);
  margin-bottom: 40px;
}
.input-wrap {
  width: 100%;
  margin-bottom: 10px;
}
.loopin-button {
  margin-top: 0px;
}
</style>
