import { Company } from "@/types/company";
import { Calendar, Talent } from "@/types/talent";
import { createApiDataHook } from ".";

export const useUserTalent = createApiDataHook<Talent>(
  ["talent", "talent"],
  "talent/loadTalent"
);

export const useUserTalentCompany = createApiDataHook<Company>(
  ["talent", "company"],
  "talent/loadCompany"
);

export const useUserTalentCalendar = createApiDataHook<Calendar>(
  ["talent", "calendar"],
  "talent/loadCalendar"
);

export const useUserTalentCalendarEvents = createApiDataHook<Calendar>(
  ["talent", "calendarEvents"],
  "talent/loadCalendarEvents"
);

export const useUserTalentHasDeals = createApiDataHook<boolean>(
  ["talent", "hasDeals"],
  "talent/loadHasDeals"
);
