import api, { authStorageKey } from "./api.service";
import { Preferences } from "@capacitor/preferences";
import { actionTypes, store } from "@/store";
import { Browser } from "@capacitor/browser";
import { parseMagicLinkParams } from "@/utils";
import { ExternalAccountService } from "@/types/user";
import { HydraListResponse } from "@/types/api";
import { isPlatform } from "@ionic/vue";

class UserService {
  connectExternalAccountSuccess: (() => void) | null = null;
  connectExternalAccountError: (() => void) | null = null;

  async login(user: { email: string; password: string }) {
    const response = await api.post<{
      token: string;
      refresh_token: string;
    }>("auth", {
      email: user.email,
      password: user.password,
    });
    await Preferences.set({
      key: authStorageKey,
      value: JSON.stringify(response.data),
    });
    return response.data;
  }

  async loginWithMagicLink(paramsStr: string) {
    await Preferences.set({
      key: authStorageKey,
      value: JSON.stringify(parseMagicLinkParams(paramsStr)),
    });
    return;
  }

  async sendMagicLink(email: string) {
    return (
      await api.get<{ success: boolean }>("public/request-magic-link", {
        params: {
          email,
          destination: isPlatform("capacitor") ? "mobile" : "webapp",
        },
      })
    ).data.success;
  }

  logout() {
    return Preferences.remove({
      key: authStorageKey,
    });
  }

  updatePersonalProfile({
    email,
    mediaAvatar,
  }: {
    email: string;
    mediaAvatar?: string;
  }) {
    const me = store.state.user.me;
    if (!me) {
      throw new Error("User not logged in");
    }
    return api.put(me["@id"], { email, mediaAvatar });
  }

  async connectExternalAccount(
    service: ExternalAccountService
  ): Promise<boolean> {
    const me = store.state.user.me;
    if (!me) {
      throw new Error("User not logged in");
    }
    const url = (
      await api.get<{ url: string }>(
        `${me["@id"]}/connect_external_account_url`,
        {
          params: { service, destination: "mobile" },
        }
      )
    ).data.url;
    Browser.open({
      url,
    });
    return new Promise((resolve) => {
      this.connectExternalAccountSuccess = async () => {
        try {
          await store.dispatch(actionTypes.user.loadExternalAccounts);
        } finally {
          resolve(true);
        }
        this.connectExternalAccountSuccess = null;
      };
      this.connectExternalAccountError = () => {
        resolve(false);
        this.connectExternalAccountError = null;
      };
    });
  }

  addAssistant({
    email,
    firstName,
    lastName,
  }: {
    email: string;
    firstName: string;
    lastName: string;
  }) {
    const talent = store.state.talent.talent?.data?.["@id"];
    if (!talent) {
      throw new Error("User not logged in");
    }
    return api.post("/talent_assistants", {
      email,
      firstName,
      lastName,
      talent,
    });
  }

  sendInvoices({ startDate, endDate }: { startDate: string; endDate: string }) {
    const talent = store.state.talent?.talent?.data;
    const me = store.state.user.me;
    if (!talent || !me) {
      throw new Error("User not logged in");
    }
    return api.post(`${talent["@id"]}/send_invoices_summary`, {
      startDate,
      endDate,
      emailRecipient: talent.ownerUser.email,
    });
  }

  async getInsights(params: { startDate?: string; endDate?: string }) {
    return (
      await api.get<{
        totalRevenue: number;
        totalRevenueWithoutCommissions: number;
        totalInvoiced: number;
        totalInvoicedPaid: number;
        totalInvoicedPaidWithTax: number;
        totalInvoicedPending: number;
        totalInvoicedPendingWithTax: number;
        dealsCount: number;
        startDate: string;
        endDate: string;
        series: HydraListResponse<{
          formattedDate: string;
          totalRevenueWithoutCommissions: number;
          totalInvoicedPaidWithTax: number;
          totalRevenue: number;
          totalDeal: number;
        }>;
      }>("me/insights", {
        params,
      })
    ).data;
  }
}
export default new UserService();
