<template>
  <div :class="['card', className, 'ion-activatable']" @click="onClick">
    <template v-if="isLoading">
      <div class="card__image">
        <ion-skeleton-text
          :style="{ width: '100%', height: '100%' }"
          animated
        />
      </div>
      <div class="card__content">
        <div class="title">
          <div class="title__text">
            <ion-skeleton-text
              :style="{ width: '120px', height: '20px' }"
              animated
            />
          </div>
        </div>
        <div class="tags">
          <ion-skeleton-text
            v-for="(tag, index) in [1, 2]"
            :key="`${tag}-${index}`"
            :style="{ width: '40px', height: '26px', marginRight: '8px' }"
            animated
          />
        </div>
      </div>
    </template>
    <template v-else>
      <ion-ripple-effect v-if="!!onClick" />
      <div class="card__image">
        <img :src="service.pictureUrl" alt="Image" />
      </div>
      <div class="card__content">
        <div class="title">
          <font-awesome-icon
            class="title__icon"
            :icon="['fas', service.icon]"
          />
          <div class="title__text">
            {{ service.name }}
          </div>
        </div>
        <div class="tags">
          <div
            class="tag"
            v-for="(tag, index) in service.tags"
            :key="`${tag}-${index}`"
          >
            {{ capitalize(tag) }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { IonRippleEffect, IonSkeletonText } from "@ionic/vue";
import { ThirdPartyService } from "@/types/thirdPartyServices";
import { capitalize } from "@/utils";

export default defineComponent({
  name: "service-card",
  components: {
    IonRippleEffect,
    IonSkeletonText,
  },
  props: {
    service: Object as PropType<ThirdPartyService>,
    className: String,
    isLoading: Boolean,
    onClick: {
      type: Function as PropType<(e: MouseEvent) => void>,
    },
  },
  setup() {
    return { capitalize };
  },
});
</script>

<style scoped>
.card {
  background: var(--loopin-item-background);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}
.card__image {
  width: 100%;
  margin-right: 12px;
  height: 180px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.card__image img {
  object-fit: cover;
  width: 100%;
}
.card__content {
  padding: 22px 24px;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.title__icon {
  margin-right: 12px;
  font-size: 24px;
}
.title__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tag {
  background: #7879f1;
  border-radius: 7px;
  height: 26px;
  padding: 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 15px;
}
</style>