<template>
  <ion-header>
    <div class="close-btn ion-activatable" @click="closeHandler">
      <IonRippleEffect />
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
  </ion-header>
  <ion-content class="text-selectable">
    <div class="header ion-padding">
      <div class="header__text">Send invoices</div>
    </div>
    <div class="form">
      <ion-item lines="none" color="background">
        <ion-label>
          {{ t("settings-page.from-date") }}
        </ion-label>
        <div>
          <ion-datetime-button
            slot="end"
            datetime="datetimeFrom"
          ></ion-datetime-button>
        </div>
      </ion-item>
      <ion-item lines="none" color="background">
        <ion-label>
          {{ t("settings-page.to-date") }}
        </ion-label>
        <div>
          <ion-datetime-button
            slot="end"
            datetime="datetimeTo"
          ></ion-datetime-button>
        </div>
      </ion-item>
    </div>

    <ion-modal :keep-contents-mounted="true">
      <ion-datetime
        id="datetimeFrom"
        :show-default-buttons="true"
        presentation="date"
        :value="form.startDate"
      ></ion-datetime>
    </ion-modal>
    <ion-modal :keep-contents-mounted="true">
      <ion-datetime
        id="datetimeTo"
        :show-default-buttons="true"
        presentation="date"
        :value="form.endDate"
      ></ion-datetime>
    </ion-modal>
  </ion-content>
  <ion-footer class="footer">
    <ion-button
      @click="onSend()"
      expand="block"
      class="loopin-button loopin-button--gradient"
      size="large"
      mode="ios"
      :disabled="sendInProgress"
    >
      {{ t("common.send") }}
    </ion-button>
  </ion-footer>
</template>

<script lang="ts">
import { useUserTalent } from "@/hooks/talent";
import errorHandlerService from "@/services/error-handler.service";
import userService from "@/services/user.service";
import {
  IonContent,
  IonHeader,
  IonButton,
  IonFooter,
  IonRippleEffect,
  modalController,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  toastController,
} from "@ionic/vue";
import { DateTime } from "luxon";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SendInvoicesModal",
  components: {
    IonContent,
    IonHeader,
    IonButton,
    IonRippleEffect,
    IonFooter,
    IonDatetime,
    IonDatetimeButton,
    IonItem,
    IonLabel,
  },
  setup() {
    const { t } = useI18n();
    const sendInProgress = ref<boolean>(false);
    const form = ref<{
      startDate: string;
      endDate: string;
    }>({
      startDate: DateTime.fromJSDate(new Date()).startOf("month").toISODate(),
      endDate: DateTime.fromJSDate(new Date()).endOf("month").toISODate(),
    });
    useUserTalent();
    return {
      t,
      form,
      sendInProgress,
      closeHandler: () => {
        modalController.dismiss();
      },
      onSend: async () => {
        try {
          sendInProgress.value = true;
          await userService.sendInvoices(form.value);

          (
            await toastController.create({
              message: "Invoices sent",
              position: "top",
              duration: 1000,
              color: "primary",
            })
          ).present();
          modalController.dismiss();
        } catch (error: any) {
          errorHandlerService.handleApiError(error);
        } finally {
          sendInProgress.value = false;
        }
      },
    };
  },
});
</script>
<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 56px;
}
.header__logo {
  width: 48px;
  margin-right: 12px;
  margin-right: 20px;
}
.header__logo-icon {
  font-size: 30px;
  color: var(--loopin-color-gs-40);
}
.header__text {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: -0.02em;
  flex: 1;
}
.footer {
  padding: 24px 24px calc(30px + var(--ion-safe-area-bottom)) 24px;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
