import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BarChart, {
      ref: "barRef",
      height: 250,
      chartData: _ctx.testData,
      options: _ctx.options,
      plugins: _ctx.plugins
    }, null, 8, ["chartData", "options", "plugins"])
  ]))
}