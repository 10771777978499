<template>
  <ion-header>
    <Badge v-if="actionInfo" :color="actionInfo.color">{{
      actionInfo.text
    }}</Badge>
    <div class="close-btn ion-activatable" @click="closeHandler">
      <IonRippleEffect />
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
  </ion-header>
  <ion-content class="text-selectable">
    <div class="info">
      <div class="info__item">
        <div
          :class="{
            'info__item-logo': true,
            'info__item-logo--icon': !company.image,
          }"
        >
          <img v-if="company.image" :src="company.image" :alt="company.title" />
          <font-awesome-icon
            v-else
            class="info__item-logo-icon"
            :icon="['fas', 'image']"
          />
        </div>
        <div class="info__item-title">
          {{ company.title }}
        </div>
      </div>
      <div class="info__item">
        <div class="info__item-logo">
          <img :src="social.image" :alt="social.title" />
        </div>
        <div class="info__item-title">
          {{ social.title }}
        </div>
      </div>
    </div>
    <div class="meta">
      <div class="meta__item">
        <div class="meta__item-icon-wrap">
          <font-awesome-icon
            class="meta__item-icon"
            :icon="['fas', 'calendar']"
          />
        </div>
        <div class="meta__item-text">
          {{ date }}
        </div>
      </div>
      <div class="meta__item">
        <div class="meta__item-icon-wrap">
          <font-awesome-icon class="meta__item-icon" :icon="['fas', 'leaf']" />
        </div>
        <div class="meta__item-text">
          {{ action }}
        </div>
      </div>
    </div>
    
    <div class="instructions">
      <div class="instructions__header" v-if="contentTitle">{{ contentTitle }}</div>
      <div v-if="instruction"><div class="instructions__header">{{ t("common.instructions") }}</div>
      <div class="instructions__content">
        {{ instruction }}
      </div></div>
      
    </div>
  </ion-content>
</template>

<script lang="ts">
import {
  IonContent,
  IonRippleEffect,
  modalController,
  IonHeader,
} from "@ionic/vue";
import Badge from "@/components/Badge.vue";
import { defineComponent, PropType } from "vue";
import { DateTime } from "luxon";
import { calendarEventActionInfo, getSocialMediaIcon } from "@/utils";
import { CalendarEvent } from "@/types/talent";
import { useI18n } from "vue-i18n";
import localeService from "@/services/locale.service";

export default defineComponent({
  name: "DeliverableModal",
  components: { IonContent, IonRippleEffect, Badge, IonHeader },
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    return {
      t,
      actionInfo: calendarEventActionInfo(props.calendarEvent),
      company: {
        title: props.calendarEvent.deliverable.deal?.brand?.name || "-",
        image:
          props.calendarEvent.deliverable.deal?.brand?.mediaLogo
            ?.readPublicLink,
      },
      social: {
        title: props.calendarEvent.deliverable.talentSocialNetwork.username,
        image: getSocialMediaIcon(
          props.calendarEvent.deliverable.talentSocialNetwork.socialNetwork.name
        ),
      },
      date: t("common.due-at", {
        date: DateTime.fromISO(props.calendarEvent.endDate)
          .setLocale(localeService.selectedLocale)
          .toLocaleString(DateTime.DATE_FULL),
      }),
      action: props.calendarEvent.deliverable.deliverableType.name,
      instruction: props.calendarEvent.deliverable.talentInstructions,
      contentTitle: props.calendarEvent.deliverable.contentTitle,
      closeHandler: () => {
        modalController.dismiss();
      },
    };
  },
});
</script>
<style scoped>
.info {
  position: relative;
}
.info__item {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.info__item:first-child::after {
  content: " ";
  position: absolute;
  width: 1px;
  height: 26px;
  left: 16px;
  top: 42px;
  background: var(--loopin-color-gs-100);
}
.info__item-logo {
  width: 60px;
  height: 32px;
}
.info__item-logo img {
  width: 32px;
}
.info__item-logo--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  margin-right: 28px;
  background: var(--loopin-color-gs-30);
  border-radius: 5px;
}
.info__item-title {
  font-size: 21px;
  line-height: 34px;
  letter-spacing: -0.01em;
  font-weight: bold;
}
.meta {
  padding-bottom: 14px;
}
.meta__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.meta__item-icon-wrap {
  width: 60px;
}
.meta__item-icon {
  color: var(--loopin-color-gs-70);
  font-size: 24px;
  width: 32px;
  text-align: center;
}
.meta__item-text {
  font-size: 16px;
  flex: 1;
}
.instructions {
  border-top: 1px solid var(--loopin-color-gs-40);
  padding-top: 29px;
}
.instructions__header {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
}
.instructions__content {
  font-size: 16px;
  line-height: 20px;
  color: var(--loopin-color-gs-90);
}
</style>