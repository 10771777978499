import { HydraBase } from "./api";
import { Media } from "./media";

export type Me = HydraBase & {
  createdAt: string;
  email: string;
  enabled: boolean;
  lastLogin: string | null;
  locale: string;
  locked: boolean;
  roles: string[];
  talent: string;
  updatedAt: string;
  mediaAvatar: Media;
};

export enum ExternalAccountService {
  google_calendar = "google_calendar",
  meta_instagram = "meta_instagram",
  google_youtube = "google_youtube",
}

export type ExternalAccount = HydraBase & {
  createdAt: string;
  enabled: boolean;
  ownerUser: string;
  profileEmail: string;
  profilePicture: string;
  service: ExternalAccountService;
  scopes: string[];
  updatedAt: string;
};

export type Assistant = HydraBase & {
  id: string;
  talent: HydraBase & {
    name: string;
    id: string;
    mediaLogo: string | null;
    birthDate: string;
  };
  owner: HydraBase & {
    email: string;
    mediaAvatar: string | null;
    firstName: string;
    id: string;
  };
};
