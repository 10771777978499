import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45ae8617"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__image" }
const _hoisted_2 = { class: "card__content" }
const _hoisted_3 = { class: "card__title" }
const _hoisted_4 = { class: "card__sub-title" }
const _hoisted_5 = {
  key: 0,
  class: "card__image"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "card__image-placeholder"
}
const _hoisted_8 = { class: "card__content" }
const _hoisted_9 = {
  key: 0,
  class: "card__title"
}
const _hoisted_10 = {
  key: 1,
  class: "card__sub-title"
}
const _hoisted_11 = { class: "card__right" }
const _hoisted_12 = {
  key: 2,
  class: "card__right-title"
}
const _hoisted_13 = {
  key: 3,
  class: "card__right-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card', _ctx.className, 'ion-activatable']),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_skeleton_text, {
              style: {"width":"46px","height":"46px","margin":"0"},
              animated: ""
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_skeleton_text, {
                style: {"width":"120px","height":"16px"},
                animated: ""
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_skeleton_text, {
                style: {"width":"80px","height":"12px"},
                animated: ""
              })
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.image || _ctx.defaultImage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.image)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.image,
                      alt: "Image"
                    }, null, 8, _hoisted_6))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "card__image-placeholder-icon",
                        icon: ['fas', 'image']
                      })
                    ]))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.subTitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.subTitle), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            (_ctx.onDescriptionClick)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDescriptionClick && _ctx.onDescriptionClick(...args)), ["stop"])),
                  class: "card__right-click-overlay"
                }))
              : _createCommentVNode("", true),
            (_ctx.badge)
              ? (_openBlock(), _createBlock(_component_Badge, {
                  key: 1,
                  color: _ctx.badge.color,
                  className: _ctx.badge.className
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.badge.text), 1)
                  ]),
                  _: 1
                }, 8, ["color", "className"]))
              : _createCommentVNode("", true),
            (_ctx.rightTitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.rightTitle), 1))
              : _createCommentVNode("", true),
            (_ctx.rightDescription)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.rightDescription), 1))
              : _createCommentVNode("", true)
          ])
        ], 64))
  ], 2))
}