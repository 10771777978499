import { HydraBase } from "./api";
import { Media } from "./media";

export enum CompanyJobType {
  PROJECT_MANAGER,
  BILLING_MANAGER,
  LEGAL_MANAGER,
  CONTRACT_SIGNER,
}

export type Company = HydraBase & {
  address: string | null;
  addressSuite: string | null;
  agency: string | null;
  brand: string | null;
  city: string | null;
  country: string;
  createdAt: string;
  invoiceNumberConfirmationFlow: boolean;
  invoiceTaxPercentage: number;
  invoiceTerms: string;
  legalForm: string;
  mediaLogo: Media | null;
  name: string;
  platform: string | null;
  signatureContractDefaultFlow: boolean;
  siret: string;
  talent: string;
  updatedAt: string;
  vatNumber: string;
  zipcode: string;
  contacts: Array<
    HydraBase & {
      email: string;
      firstName: string;
      jobTypes: CompanyJobType[];
      lastName: string;
      locale: string;
      phone: string;
    }
  >;
  signatureEmailsRecipientCompanyContact: HydraBase & {
    email: string;
    firstName: string;
    jobTypes: CompanyJobType[];
    lastName: string;
    locale: string;
    phone: string;
  };
};
