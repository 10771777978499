import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, { ref: "tabs" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createVNode(_component_ion_tab_bar, { class: "tabbar" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "home",
                href: "/tabs/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tabbar__icon",
                    icon: ['fas', 'house']
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("common.home")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "deals",
                href: "/tabs/deals"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tabbar__icon",
                    icon: ['fas', 'champagne-glasses']
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("common.deal", 2)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "revenue",
                href: "/tabs/revenue"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tabbar__icon",
                    icon: ['fas', 'chart-line']
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("common.revenue")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "services",
                href: "/tabs/services"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tabbar__icon",
                    icon: ['fas', 'bell-concierge']
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("common.services", 2)), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "settings",
                href: "/tabs/settings"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    class: "tabbar__icon",
                    icon: ['fas', 'gear']
                  }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("common.settings")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}