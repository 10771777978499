import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cf1804e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "error"
}
const _hoisted_2 = { class: "error-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message', _ctx.className])
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ion_spinner, {
          key: 0,
          class: "loader",
          color: "dark",
          name: "lines"
        }))
      : (!!_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, {
              class: "error-icon",
              icon: ['fas', 'triangle-exclamation']
            }),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.error.message || _ctx.t("error-messages.cant-load-data")), 1),
            (_ctx.error.showRetryButton)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  onClick: _ctx.onReloadClick,
                  fill: "outline",
                  size: "small",
                  color: "medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.error.reloadButtonText || _ctx.t("common.reload")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
  ], 2))
}