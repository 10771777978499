import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f7224d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header__title" }
const _hoisted_2 = { class: "tabbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_no_deals_placeholder = _resolveComponent("no-deals-placeholder")!
  const _component_deals_list = _resolveComponent("deals-list")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.hasDeals?.data !== false)
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 0,
            class: "header"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SearchInput, {
                class: "header__search-input",
                placeholder: _ctx.t('common.search-deals') + '...',
                onChange: _ctx.onSearchQueryChange
              }, null, 8, ["placeholder", "onChange"]),
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t("common.deals")), 1),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSlide(0))),
                  class: _normalizeClass({
            tabbar__item: true,
            'tabbar__item--active': _ctx.slides && _ctx.slides.activeIndex === 0,
          })
                }, _toDisplayString(_ctx.t("common.current")), 3),
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openSlide(1))),
                  class: _normalizeClass({
            tabbar__item: true,
            'tabbar__item--active': _ctx.slides && _ctx.slides.activeIndex === 1,
          })
                }, _toDisplayString(_ctx.t("common.passed")), 3)
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, { class: "content" }, {
        default: _withCtx(() => [
          (_ctx.hasDeals?.data === false)
            ? (_openBlock(), _createBlock(_component_no_deals_placeholder, { key: 0 }))
            : (_openBlock(), _createBlock(_component_swiper, {
                key: 1,
                class: "loopin-swiper",
                onSwiper: _ctx.setSwiperInstance
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_swiper_slide, { class: "slide" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_deals_list, {
                        searchQuery: _ctx.searchQuery,
                        params: { [`hasFutureDeliverables`]: 'true' },
                        showComission: _ctx.showComission,
                        onAmountClick: _ctx.switchShowComission,
                        onDealClick: _ctx.goToDetails
                      }, null, 8, ["searchQuery", "showComission", "onAmountClick", "onDealClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_swiper_slide, { class: "slide" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_deals_list, {
                        searchQuery: _ctx.searchQuery,
                        params: { [`hasFutureDeliverables`]: 'false' },
                        showComission: _ctx.showComission,
                        onAmountClick: _ctx.switchShowComission,
                        onDealClick: _ctx.goToDetails
                      }, null, 8, ["searchQuery", "showComission", "onAmountClick", "onDealClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSwiper"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}