<template>
  <div class="wrap">
    <div :class="['search-input', className]">
      <div class="icon">
        <font-awesome-icon :icon="['fas', 'search']" />
      </div>
      <ion-input
        ref="inputRef"
        v-model="inputValue"
        @input="inputHandler"
        @ionFocus="focusHandler"
        @ionBlur="blurHandler"
        class="input"
        :placeholder="placeholder"
      ></ion-input>
      <div class="clear" @click="clearHandler" v-if="focused && inputValue">
        <font-awesome-icon :icon="['fas', 'circle-xmark']" />
      </div>
    </div>
    <div class="cancel" v-if="focused" @click="cancelHandler()">
      {{ t("common.cancel") }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { IonInput } from "@ionic/vue";
import { debounce } from "@/utils";
import { useI18n } from "vue-i18n";
import { Keyboard } from "@capacitor/keyboard";
export default defineComponent({
  name: "search-input",
  components: {
    IonInput,
  },
  props: {
    className: String,
    onChange: {
      type: Function as PropType<(value: string) => void>,
    },
    placeholder: String,
  },
  setup(props) {
    const inputValue = ref<string>("");
    const focused = ref<boolean>();
    const { t } = useI18n();
    const inputHandler = debounce(() => {
      if (!props.onChange) {
        return;
      }
      props.onChange(inputValue.value);
    }, 300);
    // eslint-disable-next-line no-undef
    const inputRef = ref();
    const blurTimeout = ref<number>();
    return {
      t,
      inputHandler,
      inputRef,
      inputValue,
      focused,
      focusHandler: () => {
        focused.value = true;
      },
      blurHandler: () => {
        if (!focused.value) {
          return;
        }
        blurTimeout.value = setTimeout(() => {
          focused.value = false;
          blurTimeout.value = undefined;
        }, 500);
      },
      clearHandler: (e: Event) => {
        e.stopPropagation();
        inputValue.value = "";
        if (!inputRef.value) {
          return;
        }
        if (blurTimeout.value) {
          clearTimeout(blurTimeout.value);
          blurTimeout.value = undefined;
        }
        inputRef.value.$el.setFocus();
        inputHandler();
      },
      cancelHandler: () => {
        focused.value = false;
        Keyboard.hide();
      },
    };
  },
});
</script>

<style scoped>
.wrap {
  display: flex;
  align-items: center;
}
.search-input {
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  background: var(--loopin-item-background);
  border-radius: 12px;
  font-size: 14px;
}
.search-input .input::v-deep input {
  font-size: 14px;
}
.icon {
  margin-right: 14px;
  color: var(--loopin-color-gs-60);
  font-size: 18px;
}
.clear {
  height: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--loopin-color-gs-60);
}
.cancel {
  margin-left: 8px;
  color: var(--loopin-color-pink);
  opacity: 0.8;
}
</style>