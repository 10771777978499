import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57482fc5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text" }
const _hoisted_2 = {
  key: 0,
  class: "icon-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRippleEffect = _resolveComponent("IonRippleEffect")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['button', 'button--' + _ctx.fill, 'ion-activatable', _ctx.className])
  }, [
    _createVNode(_component_IonRippleEffect),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.rightIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, { icon: _ctx.rightIcon }, null, 8, ["icon"])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}