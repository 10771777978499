import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ListCard = _resolveComponent("ListCard")!
  const _component_empty_message = _resolveComponent("empty-message")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "slide-content" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_refresher, {
        slot: "fixed",
        class: "refresher",
        disabled: _ctx.isLoading,
        onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher_content)
        ]),
        _: 1
      }, 8, ["disabled"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList([1, 2], (deal) => {
            return _createVNode(_component_ListCard, {
              key: deal,
              isLoading: true,
              class: "deal"
            })
          }), 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!!_ctx.loadError)
              ? (_openBlock(), _createBlock(_component_empty_message, {
                  key: 0,
                  onReload: _ctx.loadDeals,
                  error: {
          error: _ctx.loadError,
          showRetryButton: true,
        }
                }, null, 8, ["onReload", "error"]))
              : (!_ctx.deals.length)
                ? (_openBlock(), _createBlock(_component_empty_message, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("common.no-deals")), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deals, (deal) => {
                      return (_openBlock(), _createBlock(_component_ListCard, {
                        key: deal.key,
                        title: deal.title,
                        subTitle: _ctx.formatDate(deal.date),
                        image: deal.image,
                        rightTitle: 
            _ctx.showComission ? _ctx.t('common.net') + ` ${deal.amountNet}` : deal.amount
          ,
                        rightDescription: 
            _ctx.showComission
              ? `${deal.commissionPercentage}% ` +
                _ctx.t('common.comission') +
                ` ${deal.amountComission}`
              : undefined
          ,
                        defaultImage: true,
                        onClick: ($event: any) => (_ctx.onDealClick(deal.deal)),
                        onDescriptionClick: _ctx.dealDescriptionClickHandler,
                        class: "deal"
                      }, null, 8, ["title", "subTitle", "image", "rightTitle", "rightDescription", "onClick", "onDescriptionClick"]))
                    }), 128)),
                    _createVNode(_component_ion_infinite_scroll, {
                      onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doInfinite($event))),
                      threshold: "100px",
                      disabled: !_ctx.nextPage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ], 64))
          ], 64))
    ]),
    _: 1
  }))
}