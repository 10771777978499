<template>
  <div :class="['message', className]">
    <template v-if="isLoading">
      <ion-spinner class="loader" color="dark" name="lines"></ion-spinner>
    </template>
    <template v-else-if="!!error">
      <div class="error">
        <font-awesome-icon
          class="error-icon"
          :icon="['fas', 'triangle-exclamation']"
        />
        <div class="error-text">
          {{ error.message || t("error-messages.cant-load-data") }}
        </div>
        <ion-button
          @click="onReloadClick"
          fill="outline"
          size="small"
          color="medium"
          v-if="error.showRetryButton"
        >
          {{ error.reloadButtonText || t("common.reload") }}
        </ion-button>
      </div>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script lang="ts">
import { IonSpinner, IonButton } from "@ionic/vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "empty-message",
  components: {
    IonSpinner,
    IonButton,
  },
  props: {
    className: String,
    isLoading: Boolean,
    error: {
      type: Object as PropType<{
        error: any;
        showRetryButton?: boolean;
        retryButtonText?: string;
        text?: string;
      }>,
    },
  },
  setup(_, { emit }) {
    const { t } = useI18n();
    return {
      t,
      onReloadClick: () => {
        emit("reload");
      },
    };
  },
});
</script>

<style scoped>
.message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100px;
  color: var(--loopin-color-gs-60);
  font-size: 14px;
}
.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-icon {
  font-size: 50px;
  margin-bottom: 20px;
}
.error-text {
  font-size: 20px;
  margin-bottom: 15px;
}
</style>