import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, storeKey } from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/loopin.css";

import "./fontawesome";
import { Chart, registerables } from "chart.js";
import smoothscroll from "smoothscroll-polyfill";

import Rollbar from "rollbar";
import localeService from "./services/locale.service";

export const rollbar = new Rollbar({
  accessToken: "11f6a9e24f344ccb9742ca24870eeb3c",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: "1.0.0",
  },
});
localeService.init().then(() => {
  Chart.register(...registerables);
  smoothscroll.polyfill();

  const app = createApp(App)
    .use(IonicVue, {
      mode: "ios",
    })
    .use(store, storeKey)
    .use(localeService.i18n)
    .use(router);

  app.config.globalProperties.$rollbar = rollbar;

  app.config.errorHandler = (err, vm) => {
    (vm as any).$rollbar.error(err);
    throw err;
  };

  app.component("font-awesome-icon", FontAwesomeIcon);

  router.isReady().then(() => {
    app.mount("#app");
  });
});
