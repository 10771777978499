import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-534e079d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__image" }
const _hoisted_2 = { class: "card__content" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title__text" }
const _hoisted_5 = { class: "tags" }
const _hoisted_6 = { class: "card__image" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "card__content" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "title__text" }
const _hoisted_11 = { class: "tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card', _ctx.className, 'ion-activatable']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_skeleton_text, {
              style: { width: '100%', height: '100%' },
              animated: ""
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ion_skeleton_text, {
                  style: { width: '120px', height: '20px' },
                  animated: ""
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2], (tag, index) => {
                return _createVNode(_component_ion_skeleton_text, {
                  key: `${tag}-${index}`,
                  style: { width: '40px', height: '26px', marginRight: '8px' },
                  animated: ""
                })
              }), 64))
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!!_ctx.onClick)
            ? (_openBlock(), _createBlock(_component_ion_ripple_effect, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: _ctx.service.pictureUrl,
              alt: "Image"
            }, null, 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_font_awesome_icon, {
                class: "title__icon",
                icon: ['fas', _ctx.service.icon]
              }, null, 8, ["icon"]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.service.name), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.tags, (tag, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tag",
                  key: `${tag}-${index}`
                }, _toDisplayString(_ctx.capitalize(tag)), 1))
              }), 128))
            ])
          ])
        ], 64))
  ], 2))
}