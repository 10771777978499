<template>
  <ion-content class="slide-content">
    <ion-refresher
      slot="fixed"
      class="refresher"
      :disabled="isLoading"
      @ionRefresh="doRefresh($event)"
    >
      <ion-refresher-content> </ion-refresher-content>
    </ion-refresher>
    <template v-if="isLoading">
      <ListCard
        v-for="deal in [1, 2]"
        :key="deal"
        :isLoading="true"
        class="deal"
      />
    </template>
    <template v-else>
      <empty-message
        v-if="!!loadError"
        @reload="loadDeals"
        :error="{
          error: loadError,
          showRetryButton: true,
        }"
      ></empty-message>
      <empty-message v-else-if="!deals.length">{{
        t("common.no-deals")
      }}</empty-message>
      <template v-else>
        <ListCard
          v-for="deal in deals"
          :key="deal.key"
          :title="deal.title"
          :subTitle="formatDate(deal.date)"
          :image="deal.image"
          :rightTitle="
            showComission ? t('common.net') + ` ${deal.amountNet}` : deal.amount
          "
          :rightDescription="
            showComission
              ? `${deal.commissionPercentage}% ` +
                t('common.comission') +
                ` ${deal.amountComission}`
              : undefined
          "
          :defaultImage="true"
          @click="onDealClick(deal.deal)"
          :onDescriptionClick="dealDescriptionClickHandler"
          class="deal"
        />
        <ion-infinite-scroll
          @ionInfinite="doInfinite($event)"
          threshold="100px"
          :disabled="!nextPage"
        >
          <ion-infinite-scroll-content> </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </template>
    </template>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref, watch } from "vue";
import {
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import ListCard from "@/components/ListCard.vue";
import EmptyMessage from "@/components/EmptyMessage.vue";
import dealsService from "@/services/deals.service";
import { Deal } from "@/types/deals";
import { DateTime } from "luxon";
import { formatPrice } from "@/utils";
import errorHandlerService from "@/services/error-handler.service";
import { useI18n } from "vue-i18n";
import api from "@/services/api.service";
import { HydraListResponse } from "@/types/api";
import localeService from "@/services/locale.service";

type DealItem = {
  id: string;
  image: string | null;
  title: string;
  date: string;
  amount: string;
  deal: Deal;
  amountNet: string;
  amountComission: string;
  commissionPercentage: number;
};

export default defineComponent({
  name: "deals-list",
  components: {
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ListCard,
    EmptyMessage,
  },
  props: {
    searchQuery: {
      type: String,
    },
    showComission: {
      type: Boolean,
    },
    params: {
      type: Object as PropType<{
        archived: boolean;
      }>,
    },
  },
  setup(params, { emit }) {
    const { t, locale } = useI18n();
    const isLoading = ref<boolean>(true);
    const loadError = ref<any | false>({});
    const deals = ref<DealItem[]>([]);
    const nextPage = ref<string>();
    onMounted(() => {
      loadDeals();
    });
    const dealToItem = (deal: Deal): DealItem => ({
      id: deal["@id"].split("/")[2],
      image: deal.brand?.mediaLogo?.readPublicLink || null,
      title: deal.brand?.name,
      date: deal.createdAt,
      amount: formatPrice(deal.amount, deal.currency),
      amountNet: formatPrice(
        deal.amount * (1 - deal.commissionPercentage / 100),
        deal.currency
      ),
      amountComission: formatPrice(
        deal.amount * (deal.commissionPercentage / 100),
        deal.currency
      ),
      commissionPercentage: deal.commissionPercentage,
      deal,
    });
    const loadDeals = async () => {
      if (loadError.value) {
        loadError.value = false;
        isLoading.value = true;
        nextPage.value = undefined;
      }
      try {
        const dealsResp = nextPage.value
          ? (await api.get<HydraListResponse<Deal>>(nextPage.value)).data
          : await dealsService.fetchDeals({
              "brand.name": params.searchQuery,
              pagination: true,
              itemsPerPage: 16,
              "order[createdAt]": 'desc',
              ...params.params,
            });
        const dealItems = dealsResp["hydra:member"].map(dealToItem);
        deals.value = nextPage.value
          ? [...deals.value, ...dealItems]
          : dealItems;
        nextPage.value = dealsResp["hydra:view"]["hydra:next"];
      } catch (error: any) {
        if (!nextPage.value) {
          loadError.value = error;
        }
        errorHandlerService.handleApiError(error);
      } finally {
        isLoading.value = false;
      }
    };
    watch(
      () => params.searchQuery,
      () => {
        nextPage.value = undefined;
        loadDeals();
      }
    );
    watch(locale, async () => {
      deals.value = [...deals.value];
    });
    return {
      t,
      isLoading,
      loadError,
      deals,
      nextPage,
      loadDeals,
      dealDescriptionClickHandler: () => {
        emit("amountClick");
      },
      formatDate: (date: string) => {
        return DateTime.fromISO(date)
          .setLocale(localeService.selectedLocale)
          .toLocaleString({ month: "short", day: "numeric" });
      },
      onDealClick: (deal: Deal) => {
        emit("dealClick", deal);
      },
      doInfinite: async (event: any) => {
        try {
          await loadDeals();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
      doRefresh: async (event: any) => {
        nextPage.value = undefined;
        try {
          await loadDeals();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
    };
  },
});
</script>

<style scoped>
.slide-content {
  --padding: 0 20px 50px 20px;
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 50px;
}
.deal {
  margin-bottom: 16px;
}
</style>