<template>
  <ion-header>
    <Badge :color="invoice?.paymentStatus && invoice?.status != 2 ? paymentStatus.color : status.color">
      {{ invoice?.paymentStatus && invoice?.status != 2 ? paymentStatus.text : status.text }}
    </Badge>
    <div class="close-btn ion-activatable" @click="closeHandler">
      <IonRippleEffect />
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
  </ion-header>
  <ion-content class="ion-padding text-selectable">
    <div class="header">
      <div class="header__logo">
        <img
          v-if="invoice.deal?.contractingCompany?.mediaLogo?.readPublicLink"
          :src="invoice.deal?.contractingCompany?.mediaLogo?.readPublicLink"
          :alt="invoice.deal?.brand?.name"
        />
        <font-awesome-icon
          class="header__logo-icon"
          v-else
          :icon="['fas', 'image']"
        />
      </div>
      <div class="header__text" @click="navigateToDealDetails">
        {{ invoice.deal?.brand?.name }}
      </div>
    </div>
    <div class="data">
      <div class="data-header">
        <div class="data-header__item">
          <div class="data-header__label">
            {{ t("common.invoice-number") }}
          </div>
          <div class="data-header__value">
            {{ invoice.documentNumber }}
          </div>
        </div>
        <div class="data-header__item">
          <div class="data-header__label">
            {{ t("common.invoice-date") }}
          </div>
          <div class="data-header__value">
            {{ dateStr }}
          </div>
        </div>
      </div>
      <div class="data-table">
        <hr class="data-table__separator" />
        <div class="data-table__row">
          <div class="data-table__row-label">
            {{ t("common.subtotal") }}
          </div>
          <div class="data-table__row-value">
            {{ amount }}
          </div>
        </div>
        <div class="data-table__row">
          <div class="data-table__row-label">{{ t("common.vat") }}</div>
          <div class="data-table__row-value">
            {{ vat }}
          </div>
        </div>
        <div class="data-table__row">
          <div class="data-table__row-label">{{ t("common.total") }}</div>
          <div class="data-table__row-value">
            {{ total }}
          </div>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer class="footer">
    <ion-button
      @click="download()"
      expand="block"
      class="loopin-button loopin-button--gradient"
      size="large"
      mode="ios"
    >
      {{ t("common.download-pdf") }}
    </ion-button>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonButton,
  IonFooter,
  IonRippleEffect,
  modalController,
  useIonRouter,
} from "@ionic/vue";
import Badge from "@/components/Badge.vue";
import { defineComponent, PropType } from "vue";
import { Invoice } from "@/types/deals";
import { formatPrice, invoiceStatusDetails, paymentStatusDetails } from "@/utils";
import { DateTime } from "luxon";
import { Browser } from "@capacitor/browser";
import { useI18n } from "vue-i18n";
import localeService from "@/services/locale.service";

export default defineComponent({
  name: "InvoiceModal",
  components: {
    IonContent,
    IonHeader,
    IonButton,
    Badge,
    IonRippleEffect,
    IonFooter,
  },
  props: {
    invoice: {
      type: Object as PropType<Invoice>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useIonRouter();
    return {
      t,
      dateStr: DateTime.fromISO(props.invoice.issuedAt)
        .setLocale(localeService.selectedLocale)
        .toLocaleString(DateTime.DATE_FULL),
      status: invoiceStatusDetails(props.invoice.status),
      amount: formatPrice(props.invoice.totalBeforeTax, props.invoice.currency),
      paymentStatus: paymentStatusDetails(props.invoice.paymentStatus),
      subTotal: formatPrice(
        props.invoice.totalBeforeTax -
          Math.round(
            props.invoice.totalBeforeTax * (props.invoice.taxPercentage / 100)
          ),
        props.invoice.currency
      ),
      vat: formatPrice(
        Math.round(
          props.invoice.totalBeforeTax * (props.invoice.taxPercentage / 100)
        ),
        props.invoice.currency
      ),
      total: formatPrice(
        props.invoice.totalBeforeTax +
          Math.round(
            props.invoice.totalBeforeTax * (props.invoice.taxPercentage / 100)
          ),
        props.invoice.currency
      ),
      closeHandler: () => {
        modalController.dismiss();
      },
      download: () => {
        Browser.open({
          url: props.invoice.media.readPublicLink,
        });
      },
      navigateToDealDetails: () => {
        modalController.dismiss();
        router.push(
          `/tabs/deals/details/${props.invoice.deal["@id"].split("/")[2]}`
        );
      },
    };
  },
});
</script>
<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 56px;
}
.header__logo {
  width: 48px;
  margin-right: 12px;
  margin-right: 20px;
}
.header__logo-icon {
  font-size: 30px;
  color: var(--loopin-color-gs-40);
}
.header__text {
  font-size: 28px;
  line-height: 48px;
  font-weight: bold;
  letter-spacing: -0.02em;
  flex: 1;
}
.data {
}
.data-header {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
}
.data-header::after {
  width: 1px;
  height: 56px;
  background: var(--loopin-color-gs-40);
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.data-header__item {
}
.data-header__item:nth-child(2) {
  text-align: right;
}
.data-header__label {
  margin-bottom: 14px;
}
.data-header__value {
}
.data-table {
}
.data-table__separator {
  height: 1px;
  background: var(--loopin-color-gs-40);
  margin: 24px 0;
}
.data-table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.data-table__row-label {
}
.data-table__row-value {
}

.data-header__value,
.data-table__row-value {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
}

.data-table__row-label,
.data-header__label {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: var(--loopin-color-gs-90);
}

.footer {
  padding: 24px 24px calc(30px + var(--ion-safe-area-bottom)) 24px;
}
</style>