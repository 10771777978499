import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cbbae56"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["set"]
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__title loopin-list-heading" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "day__header" }
const _hoisted_6 = { class: "day__content" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "item__time" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "card__title" }
const _hoisted_11 = { class: "card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_empty_message = _resolveComponent("empty-message")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_IonRippleEffect = _resolveComponent("IonRippleEffect")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", {
    class: "calendar",
    set: (_ctx.visibleDays = _ctx.isLoading ? _ctx.skeletonDays : _ctx.days)
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
      (!_ctx.hideCalendarButton)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            class: "header__icon",
            icon: ['fas', 'calendar']
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.isLoading && !_ctx.days.length)
        ? (_openBlock(), _createBlock(_component_empty_message, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("common.no-events")), 1)
            ]),
            _: 1
          }))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.visibleDays, (day) => {
            return (_openBlock(), _createElementBlock("div", {
              key: day.key,
              class: "day"
            }, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                      key: 0,
                      style: {"width":"40px","height":"12px"},
                      animated: ""
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(day.title), 1)
                    ], 64))
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.items, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item",
                    key: item.key,
                    onClick: ($event: any) => (_ctx.isLoading ? undefined : _ctx.openModal(item))
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      (_ctx.isLoading)
                        ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                            key: 0,
                            style: {"width":"35px","height":"8px"},
                            animated: ""
                          }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(item.time), 1)
                          ], 64))
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass([
                  'card',
                  _ctx.isLoading ? 'card--loading' : 'card--' + item.color,
                  'ion-activatable',
                ])
                    }, [
                      _createVNode(_component_IonRippleEffect),
                      (!_ctx.isLoading && item.action)
                        ? (_openBlock(), _createBlock(_component_Badge, {
                            key: 0,
                            className: "card__badge",
                            color: item.color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.action), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass(['card__image', 'card__image--' + item.socialMedia])
                      }, [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                              key: 0,
                              style: {"width":"24px","height":"24px"},
                              animated: ""
                            }))
                          : (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              src: _ctx.getSocialMediaIcon(item.socialMedia),
                              alt: item.socialMedia
                            }, null, 8, _hoisted_9))
                      ], 2),
                      _createElementVNode("div", _hoisted_10, [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                              key: 0,
                              style: {"width":"60%","height":"14px"},
                              animated: ""
                            }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ], 64))
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        (_ctx.isLoading)
                          ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                              key: 0,
                              style: {"width":"45%","height":"10px"},
                              animated: ""
                            }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(item.description), 1)
                            ], 64))
                      ])
                    ], 2)
                  ], 8, _hoisted_7))
                }), 128))
              ])
            ]))
          }), 128))
    ])
  ], 8, _hoisted_1))
}