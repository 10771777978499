// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { Preferences } from "@capacitor/preferences";
import { createI18n, I18n } from "vue-i18n";
import en_messages from "@/i18n/en.json";
import fr_messages from "@/i18n/fr.json";
import { WritableComputedRef } from 'vue';


const selectedLanguageKey = "loopin:selected-language";

const defaultLocale = "en";

class LocaleService {
  private availableLocales = ["en", "fr"];
  selectedLocale = defaultLocale;
  i18n!: I18n<{ fr: any; en: any }, any, any, false>;

  async init() {
    const savedLocale = (await Preferences.get({ key: selectedLanguageKey })).value;
    if (savedLocale) {
      this.selectedLocale = savedLocale;
    } else {
      this.selectedLocale = this.getPhoneLocale();
    }
    this.i18n = createI18n({
      legacy: false,
      locale: this.selectedLocale,
      fallbackLocale: defaultLocale,
      messages: {
        en: en_messages,
        fr: fr_messages,
      },
      sync: true,
    });
    (window as any).i18n = this.i18n;
  }

  async setLanguage(locale: string) {
    this.selectedLocale = locale;
    (this.i18n.global.locale as WritableComputedRef<string>).value = locale;
    await Preferences.set({ key: selectedLanguageKey, value: locale });
  }

  t(str: string) {
    return this.i18n.global.t(str);
  }

  languagesList() {
    return [
      {
        locale: "en",
        title: this.i18n?.global.t("languages.en-title"),
      },
      {
        locale: "fr",
        title: this.i18n?.global.t("languages.fr-title"),
      },
    ];
  }

  private getPhoneLocale() {
    return this.availableLocales.includes(window.navigator.language)
      ? window.navigator.language
      : this.availableLocales.includes(window.navigator.language.split("-")[0])
      ? window.navigator.language.split("-")[0]
      : "en";
  }
}

export default new LocaleService();
