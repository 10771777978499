<template>
  <ion-page>
    <ion-content :fullscreen="true" class="content">
      <div class="wrap">
        <img :src="require('@/assets/images/newsletter-3d.png')" />
        <div class="title">
          {{ t("login-link-sent-page.title") }}
        </div>
        <div class="subtitle">
          {{ t("login-link-sent-page.subtitle") }}
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "LoginLinkSentPage",
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<style scoped>
.content {
  --overflow: hidden;
}
.wrap {
  padding: 0 52px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wrap img {
  width: 100%;
  max-width: 160px;
  margin: 0 auto 32px auto;
}
.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
  margin-bottom: 24px;
}
.subtitle {
  font-size: 26px;
  line-height: 25px;
  color: var(--loopin-color-gs-60);
}
</style>
