import axios from "axios";
import { Preferences } from "@capacitor/preferences";
import { actionTypes, store } from "@/store";
import router from "@/router";

export const authStorageKey = "loopin:authData";

const api = axios.create({
  baseURL: "https://api.loopin.ai",
  headers: {
    platform: "loopin",
    "Content-Type": "application/json",
  },
});

const refreshPath = "auth/token/refresh";

class RefreshHelper {
  refreshInProgress = false;
  refreshResult?: Promise<boolean>;

  async refresh() {
    this.refreshResult = new Promise((resolve) => {
      return (async () => {
        this.refreshInProgress = true;
        const authData = (await Preferences.get({ key: authStorageKey })).value;
        if (!authData) {
          this.refreshInProgress = false;
          return resolve(false);
        }
        try {
          const refreshToken = JSON.parse(authData).refresh_token;
          const refreshResponse = await api.post<{
            token: string;
            refresh_token: string;
          }>(refreshPath, {
            refresh_token: refreshToken,
          });
          if (refreshResponse.status === 200 && refreshResponse.data) {
            await Preferences.set({
              key: authStorageKey,
              value: JSON.stringify(refreshResponse.data),
            });
            return resolve(true);
          } else {
            return resolve(false);
          }
        } catch (error) {
          return resolve(false);
        } finally {
          this.refreshInProgress = false;
        }
      })();
    });
    return this.refreshResult;
  }
}

const refreshHelper = new RefreshHelper();

api.interceptors.request.use(async (config) => {
  const authData = (await Preferences.get({ key: authStorageKey })).value;
  let token = "";
  if (authData) {
    try {
      token = JSON.parse(authData).token;
    } catch (error) {
      await Preferences.remove({ key: authStorageKey });
    }
  }
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const logout = async () => {
      await store.dispatch(actionTypes.user.logout);
      router.replace("/");
    };
    if (
      store.state.user.isAuthenticated &&
      error.response.status === 401 &&
      error.config.url !== refreshPath
    ) {
      if (originalRequest._retry) {
        await logout();
      }
      if (refreshHelper.refreshInProgress && refreshHelper.refreshResult) {
        const refreshResultResp = await refreshHelper.refreshResult;
        if (refreshResultResp) {
          return api(originalRequest);
        }
      }
      originalRequest._retry = true;
      const refreshResp = await refreshHelper.refresh();
      if (refreshResp) {
        return api(originalRequest);
      } else {
        await logout();
      }
    }
    return Promise.reject(error);
  }
);

export default api;
