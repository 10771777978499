import { HydraBase } from "./api";
import { Deliverable } from "./deals";

export type Talent = HydraBase & {
  affiliate: null | any;
  affiliateEndedAt: null | string;
  affiliatePercent: null | string;
  affiliateStartedAt: null | string;
  calendar: string;
  mediaLogo: null | string;
  name: string;
  createdAt: string;
  updatedAt: string;
  categories: Array<
    HydraBase & {
      name: string;
    }
  >;
  company: HydraBase & {
    mediaLogo: null | string;
    name: string;
  };
  ownerUser: HydraBase & {
    email: string;
  };
  talentSocialNetworks: Array<
    HydraBase & {
      followersCount: null | number;
      username: string;
      viewersCount: null | number;
      socialNetwork: HydraBase & {
        name: string;
      };
    }
  >;
};

export type Calendar = HydraBase & {
  createdAt: string;
  externalAccount: string;
  name: string;
  talent: string;
  updatedAt: string;
};

export enum CalendarEventType {
  script,
  submit,
  publish,
  shoot
}

export type CalendarEvent = HydraBase & {
  calendar: string;
  createdAt: string;
  deliverable: Deliverable;
  description: string;
  endDate: string;
  eventType: CalendarEventType;
  location: string | null;
  startDate: string;
  summary: string;
  updatedAt: string;
};
