<template>
    <ion-header>
        <div class="title">🔔 {{t('notifications-modal.title')}}
        </div>
        <div class="close-btn ion-activatable" @click="closeHandler">
      <IonRippleEffect />
      <font-awesome-icon :icon="['fas', 'times']" />
    </div>
    </ion-header>
    <ion-content>
        <div
        class="loopin-no-permissions-warning"
        v-if="hasPermissions === false"
      >
        {{ t("error-messages.no-notifications-permissions") }}
      </div>
        <div class="content">
            <div>{{t('notifications-modal.content[0]')}}</div>
            <div>{{t('notifications-modal.content[1]')}}</div>

            <ul>
                <li>{{t('notifications-modal.list[0]')}}</li>
                <li>{{t('notifications-modal.list[1]')}}</li>
            </ul>

            <div style="margin-bottom:15px;">{{t('notifications-modal.content[2]')}}</div>
            <ion-toggle :disabled="hasPermissions === false" @ionChange="enableNotificationsChange"
                :checked="notificationsInfo?.enabled">
            </ion-toggle>
        </div>

    </ion-content>
</template>
  
<script lang="ts">
import { IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonToggle, onIonViewWillEnter, onIonViewDidEnter, isPlatform, modalController, IonRippleEffect } from '@ionic/vue';
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useUserTalent } from "@/hooks/talent";
import errorHandlerService from "@/services/error-handler.service";
import notificationsService, {
    NotificationsInfo,
} from "@/services/notifications.service";
export default defineComponent({
    name: "NotificationsAlert",
    components: { IonHeader, IonContent, IonToggle },
    setup() {
        const { t } = useI18n();
        const talent = useUserTalent();
        const notificationsInfo = ref<NotificationsInfo>();
        const hasPermissions = ref<boolean>();

        onBeforeUnmount(() => {
            notificationsService.getNotificationsInfo().then((info) => {
                notificationsInfo.value = info;
            });
        });
        onMounted(() => {
            if (!isPlatform("capacitor")) {
                hasPermissions.value = false;
                return;
            }
            notificationsService.register().then((permissions) => {
                hasPermissions.value = permissions;
                notificationsService.getNotificationsInfo().then((info) => {
                    notificationsInfo.value = info;
                }).catch(error => {
                    console.error("Error getting notifications info:", error);
                    // Handle error appropriately
                });
            }).catch(error => {
                console.error("Error registering notifications:", error);
                // Handle error appropriately
            });
        });      
        return {
            t,
            hasPermissions,
            notificationsInfo,
            enableNotificationsChange: async (e: any) => {
                if (!talent.value?.data) {
                    return;
                }
                try {
                    if (e.target.checked) {
                        notificationsInfo.value = await notificationsService.enable();
                    } else {
                        notificationsInfo.value = await notificationsService.disable();
                    }
                } catch (error: any) {
                    errorHandlerService.handleApiError(error);
                }
            },
            closeHandler: () => {
                modalController.dismiss();
            },
        };
    },
})

</script>

<style scoped>
/* .title {
    font-size: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px 10px 30px;
}

.content {
    padding: 0px 30px 0px 30px;
}

.content div, ul {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
} */
</style>
  