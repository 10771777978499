import api, { authStorageKey } from "@/services/api.service";
import userService from "@/services/user.service";
import { Preferences } from "@capacitor/preferences";
import { Assistant, ExternalAccount, Me } from "@/types/user";
import { mutationTypes as talentMutationTypes } from "@/store/modules/talent";
import { createApiModule, StoreApiData } from "../generic";
import { HydraListResponse } from "@/types/api";
import { Media } from "@/types/media";
import notificationsService from "@/services/notifications.service";
import { rollbar } from "@/main";

export const actionTypes = {
  login: "user/login",
  loginWithMagicLink: "user/loginWithMagicLink",
  logout: "user/logout",
  init: "user/init",
  loadMe: "user/loadMe",
  loadExternalAccounts: "user/loadExternalAccounts",
  loadAssistants: "user/loadAssistants",
};

export const mutationTypes = {
  setIsAuthenticated: "user/setIsAuthenticated",
  setInitialized: "user/setInitialized",
  setMe: "user/setMe",
  reset: "user/reset",
  updateAvatar: "user/updateAvatar",
  removeExternalAccount: "user/removeExternalAccount",
  removeAssistants: "user/removeAssistants",
};

export type UserState = {
  me?: Me;
  isAuthenticated: boolean;
  isInitialized: boolean;
  externalAccounts?: StoreApiData<ExternalAccount[]>;
  assistants?: StoreApiData<Assistant[]>;
};

const userStore = createApiModule<UserState>(
  "user",
  ({ baseModule, apiDataActionHelper }) => {
    return {
      state: () => {
        return {
          isAuthenticated: false,
          isInitialized: false,
        };
      },
      actions: {
        async [actionTypes.init]({ commit }) {
          const authData = (await Preferences.get({ key: authStorageKey })).value;
          if (authData) {
            notificationsService.init();
          }
          commit(mutationTypes.setIsAuthenticated, !!authData);
          commit(mutationTypes.setInitialized, true);
        },
        async [actionTypes.loadMe]({ commit }) {
          const me = (await api.get<{ user: Me }>("me")).data.user;
          commit(mutationTypes.setMe, me);
        },
        async [actionTypes.login](
          { commit, dispatch },
          payload: { email: string; password: string }
        ) {
          try {
            const resp = await userService.login(payload);
            await dispatch(actionTypes.loadMe);
            try {
              await notificationsService.init();
            } catch (error: any) {
              // not critical, fail silently
              rollbar.error(error);
            }
            commit(mutationTypes.setIsAuthenticated, true);
            return resp;
          } catch (error) {
            commit(mutationTypes.setIsAuthenticated, false);
            throw error;
          }
        },
        async [actionTypes.loginWithMagicLink](
          { commit, dispatch },
          payload: string
        ) {
          try {
            await userService.loginWithMagicLink(payload);
            await dispatch(actionTypes.loadMe);
            try {
              await notificationsService.init();
            } catch (error: any) {
              // not critical, fail silently
              rollbar.error(error);
            }
            commit(mutationTypes.setIsAuthenticated, true);
          } catch (error) {
            commit(mutationTypes.setIsAuthenticated, false);
            throw error;
          }
        },
        async [actionTypes.logout]({ commit }) {
          commit(mutationTypes.setIsAuthenticated, false);
          await notificationsService.reset();
          await userService.logout();
          commit(mutationTypes.reset);
          commit(talentMutationTypes.reset);
        },
        async [actionTypes.loadExternalAccounts]({
          commit,
          rootState,
          dispatch,
        }) {
          return apiDataActionHelper("externalAccounts", commit, async () => {
            if (!rootState.user.me) {
              await dispatch(actionTypes.loadMe);
            }
            const userPath = rootState.user.me ? rootState.user.me["@id"] : "";
            return (
              await api.get<HydraListResponse<ExternalAccount>>(
                `${userPath}/external_accounts`
              )
            ).data["hydra:member"];
          });
        },
        async [actionTypes.loadAssistants]({ commit, rootState, dispatch }) {
          return apiDataActionHelper("assistants", commit, async () => {
            if (!rootState.user.me) {
              await dispatch(actionTypes.loadMe);
            }
            return (
              await api.get<HydraListResponse<Assistant>>(`/talent_assistants`)
            ).data["hydra:member"];
          });
        },
      },
      mutations: {
        ...baseModule.mutations,
        [mutationTypes.setIsAuthenticated](state, payload: boolean) {
          state.isAuthenticated = payload;
        },
        [mutationTypes.setInitialized](state, payload: boolean) {
          state.isInitialized = payload;
        },
        [mutationTypes.setMe](state, payload: Me) {
          state.me = payload;
        },
        [mutationTypes.reset](state) {
          state.me = undefined;
          state.isAuthenticated = false;
        },
        [mutationTypes.updateAvatar](state, avatar: Media) {
          if (!state.me) {
            return;
          }
          state.me.mediaAvatar = avatar;
        },
        [mutationTypes.removeExternalAccount](state, id: string) {
          if (!state.externalAccounts?.data) {
            return;
          }
          state.externalAccounts = {
            ...state.externalAccounts,
            data: state.externalAccounts.data.filter(
              (item) => item["@id"] !== id
            ),
          };
        },
        [mutationTypes.removeAssistants](state, id: string) {
          if (!state.assistants?.data) {
            return;
          }
          state.assistants = {
            ...state.assistants,
            data: state.assistants.data.filter((item) => item["@id"] !== id),
          };
        },
      },
      getters: {},
    };
  }
);

export default userStore;
