<template>
  <div class="calendar" :set="(visibleDays = isLoading ? skeletonDays : days)">
    <div class="header">
      <div class="header__title loopin-list-heading">
        {{ title }}
      </div>
      <font-awesome-icon
        v-if="!hideCalendarButton"
        class="header__icon"
        :icon="['fas', 'calendar']"
      />
    </div>
    <div class="content">
      <empty-message v-if="!isLoading && !days.length">{{
        t("common.no-events")
      }}</empty-message>
      <template v-else>
        <div v-for="day in visibleDays" :key="day.key" class="day">
          <div class="day__header">
            <ion-skeleton-text
              v-if="isLoading"
              style="width: 40px; height: 12px"
              animated
            />
            <template v-else>
              {{ day.title }}
            </template>
          </div>
          <div class="day__content">
            <div
              class="item"
              v-for="item in day.items"
              :key="item.key"
              @click="isLoading ? undefined : openModal(item)"
            >
              <div class="item__time">
                <ion-skeleton-text
                  v-if="isLoading"
                  style="width: 35px; height: 8px"
                  animated
                />
                <template v-else>
                  {{ item.time }}
                </template>
              </div>
              <div
                :class="[
                  'card',
                  isLoading ? 'card--loading' : 'card--' + item.color,
                  'ion-activatable',
                ]"
              >
                <IonRippleEffect />
                <Badge
                  v-if="!isLoading && item.action"
                  className="card__badge"
                  :color="item.color"
                  >{{ item.action }}</Badge
                >
                <div
                  :class="['card__image', 'card__image--' + item.socialMedia]"
                >
                  <ion-skeleton-text
                    v-if="isLoading"
                    style="width: 24px; height: 24px"
                    animated
                  />
                  <img
                    v-else
                    :src="getSocialMediaIcon(item.socialMedia)"
                    :alt="item.socialMedia"
                  />
                </div>
                <div class="card__title">
                  <ion-skeleton-text
                    v-if="isLoading"
                    style="width: 60%; height: 14px"
                    animated
                  />
                  <template v-else>
                    {{ item.title }}
                  </template>
                </div>
                <div class="card__description">
                  <ion-skeleton-text
                    v-if="isLoading"
                    style="width: 45%; height: 10px"
                    animated
                  />
                  <template v-else>
                    {{ item.description }}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";
import { AppColor, SocialMedia } from "@/types";
import { IonRippleEffect, modalController, IonSkeletonText } from "@ionic/vue";
import Badge from "@/components/Badge.vue";
import DeliverableModal from "@/modals/Deliverable.vue";
import { DateTime } from "luxon";
import { calendarEventActionInfo, getSocialMediaIcon } from "@/utils";
import EmptyMessage from "@/components/EmptyMessage.vue";
import { CalendarEvent } from "@/types/talent";
import { useI18n } from "vue-i18n";
import localeService from "@/services/locale.service";

type CalendarItem = {
  key: string;
  title: string;
  description: string;
  date: string;
  time: string;
  socialMedia: SocialMedia;
  action: string;
  color: AppColor;
  calendarEvent?: CalendarEvent;
};

type DayItem = {
  title: string;
  items: CalendarItem[];
  key: string;
};

const skeletonDays: DayItem[] = [
  {
    title: "",
    items: [
      {
        key: "1-0",
        title: "",
        description: "",
        time: "",
        date: "",
        socialMedia: SocialMedia.facebook,
        action: "Submit",
        color: AppColor.red,
      },
      {
        key: "1-1",
        title: "",
        description: "",
        time: "",
        date: "",
        socialMedia: SocialMedia.facebook,
        action: "Submit",
        color: AppColor.red,
      },
    ],
    key: "1",
  },
  {
    title: "",
    items: [
      {
        key: "2-0",
        title: "",
        description: "",
        time: "",
        date: "",
        socialMedia: SocialMedia.facebook,
        action: "Submit",
        color: AppColor.red,
      },
      {
        key: "2-1",
        title: "",
        description: "",
        time: "",
        date: "",
        socialMedia: SocialMedia.facebook,
        action: "Submit",
        color: AppColor.red,
      },
    ],
    key: "2",
  },
];

const calendarEventsToDays = (calendarEvents: CalendarEvent[]) => {
  const days: DayItem[] = [];
  let calendarItems: CalendarItem[] = [];
  calendarEvents.forEach((event) => {
    const { deliverable, startDate } = event;
    const actionInfo = calendarEventActionInfo(event);
    calendarItems.push({
      key: event["@id"],
      title: deliverable.deal?.brand?.name || "",
      description: deliverable?.deliverableType?.name,
      time: DateTime.fromISO(startDate)
        .setLocale(localeService.selectedLocale)
        .toLocaleString(DateTime.TIME_SIMPLE),
      socialMedia: deliverable?.deliverableType?.socialNetwork?.name as SocialMedia,
      action: actionInfo?.text,
      color: actionInfo?.color,
      date: startDate,
      calendarEvent: event,
    });
  });
  calendarItems.sort((a, b) => {
    if (a.date > b.date) {
      return 1;
    } else if (a.date < b.date) {
      return -1;
    }
    return 0;
  });
  calendarItems.forEach((item) => {
    const dayTitle = DateTime.fromISO(item.date)
      .setLocale(localeService.selectedLocale)
      .toLocaleString({ month: "short", day: "numeric" });
    let day = days.find((item) => item.title === dayTitle);
    if (!day) {
      day = { title: dayTitle, items: [], key: dayTitle };
      days.push(day);
    }
    day.items.push(item);
  });
  return days;
};

export default defineComponent({
  name: "calendar-component",
  components: {
    Badge,
    IonRippleEffect,
    IonSkeletonText,
    EmptyMessage,
  },
  props: {
    title: String,
    hideCalendarButton: Boolean,
    isLoading: Boolean,
    calendarEvents: Array as PropType<CalendarEvent[]>,
  },
  setup(props) {
    const { t, locale } = useI18n();
    const days = ref<DayItem[]>(
      props.calendarEvents && props.calendarEvents.length
        ? calendarEventsToDays(props.calendarEvents)
        : []
    );
    watch(
      () => props.calendarEvents,
      (calendarEvents) => {
        days.value =
          calendarEvents && calendarEvents.length
            ? calendarEventsToDays(calendarEvents)
            : [];
      }
    );
    watch(locale, async () => {
      days.value =
        props.calendarEvents && props.calendarEvents.length
          ? calendarEventsToDays(props.calendarEvents)
          : [];
    });
    return {
      t,
      days,
      skeletonDays,
      getSocialMediaIcon,
      openModal: async (item: CalendarItem) => {
        const modal = await modalController.create({
          component: DeliverableModal,
          componentProps: {
            calendarEvent: item.calendarEvent,
          },
          handle: false,
          cssClass: "loopin-modal",
        });
        modal.present();
      },
    };
  },
});
</script>

<style scoped>
.calendar {
  padding: 0 20px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.header__title {
  margin-bottom: 0;
}
.header__icon {
  color: var(--loopin-color-gs-70);
  font-size: 20px;
}
.content {
}
.day {
}
.day__header {
  text-transform: uppercase;
  color: var(--loopin-color-gs-60);
  position: relative;
}
.day__header::after {
  content: " ";
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  height: 1px;
  background: var(--loopin-color-gs-50);
  top: 50%;
  transform: translateY(-50%);
}
.day__content {
  padding: 30px 0;
}
.item {
  display: flex;
  align-items: center;
}
.item__time {
  width: 80px;
  color: var(--loopin-color-gs-40);
  font-size: 13px;
  position: relative;
  bottom: 7px;
}
.card {
  flex: 1;
  border-radius: 4px 12px 12px 4px;
  overflow: hidden;
  margin-bottom: 14px;
  position: relative;
  background: var(--loopin-item-background);
  padding: 18px 16px;
  transform: translate3d(0, 0, 0);
}
.card::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--loopin-color-gs-40);
}
.card--pink::after {
  background: var(--loopin-color-pink);
}
.card--red::after {
  background: var(--loopin-color-red);
}
.card--orange::after {
  background: var(--loopin-color-orange);
}
.card--loading::after {
  background: var(--loopin-color-gs-40);
}
.card__badge {
  position: absolute !important;
  transform: translate3d(0, 0, 0);
  top: 18px;
  right: 15px;
}
.card__image {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}
.card__title {
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
}
.card__description {
  font-size: 12px;
  color: var(--loopin-color-gs-70);
  margin-top: 2px;
}
.card__description-icon {
  margin-left: 4px;
}
</style>