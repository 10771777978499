import {
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import api from "./api.service";
import { Preferences } from "@capacitor/preferences";
import { isPlatform, toastController } from "@ionic/vue";
import { FCM } from "@capacitor-community/fcm";
import { Device } from "@capacitor/device";
import { HydraBase, HydraListResponse } from "@/types/api";
import { rollbar } from "@/main";
import localeService from "./locale.service";
import { Capacitor } from "@capacitor/core";

export type NotificationsInfo = {
  enabled: boolean;
  id?: string;
};

const notificationsStorageKey = "loopin:notifications:v3";
const notificationsRegisteredStorageKey = "loopin:notifications:registered";

class NotificationsService {
  onNavigationCallback?: (url: string) => void;

  async getNotificationsInfo(): Promise<NotificationsInfo> {
    const storageInfo = (await Preferences.get({ key: notificationsStorageKey }))
      .value;
    if (!storageInfo) {
      return {
        enabled: false,
      };
    }
    try {
      return JSON.parse(storageInfo) as NotificationsInfo;
    } catch (error) {
      console.error(error);
      return {
        enabled: false,
      };
    }
  }

  private onNotificationClick(notification: PushNotificationSchema) {
    const dealId = notification.data.dealId?.split("/")[2];
    switch (notification.data.type) {
      case "payment_request":
        if (!dealId) {
          return;
        }
        if (this.onNavigationCallback) {
          this.onNavigationCallback(`/tabs/deals/details/${dealId}`);
        }
        break;
      case "calendar_event_reminder": {
        if (!dealId) {
          return;
        }
        const calendarEventId =
          notification.data.calendarEventId?.split("/")[2];
        if (this.onNavigationCallback) {
          this.onNavigationCallback(
            `/tabs/deals/details/${dealId}/${calendarEventId}`
          );
        }
        break;
      }
    }
  }

  private async showNotificationToast(
    notification: PushNotificationSchema,
    disableClickEvent?: boolean
  ) {
    const toast = await toastController.create({
      header: notification.title,
      message: notification.body,
      position: "top",
      duration: 3000,
      color: "primary",
      buttons: disableClickEvent
        ? undefined
        : [
            {
              text: localeService.t("common.open"),
              role: "close",
              handler: () => {
                this.onNotificationClick(notification);
              },
            },
          ],
    });
    await toast.present();
  }

  setEvents() {
    PushNotifications.addListener("pushNotificationActionPerformed", (e) => {
      if (e.actionId === "tap") {
        this.onNotificationClick(e.notification);
        this.showNotificationToast(e.notification, true);
      }
    });
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        this.showNotificationToast(notification);
      }
    );
  }

  async init() {
    if (!isPlatform("capacitor")) {
      return;
    }
    if (!(await this.isRegistered())) {
      try {
        await this.register(true);
        await this.enable();
      } catch (error: any) {
        // fail silently
        rollbar.error(error);
      }
      this.setEvents();
      return;
    }
    this.setEvents();
    let savedDevice: any = undefined;
    try {
      const token = await this.getToken();
      const devices = (
        await api.get<
          HydraListResponse<
            HydraBase & {
              token: string;
            }
          >
        >("user_devices")
      ).data["hydra:member"];
      if (!devices || !devices.length) {
        return;
      }
      savedDevice = devices.find((item) => item.token === token);
    } catch (error: any) {
      // fail silently
      rollbar.error(error);
      return;
    }
    if (!savedDevice) {
      return;
    }
    const info: NotificationsInfo = {
      enabled: true,
      id: savedDevice["@id"],
    };
    await Preferences.set({
      key: notificationsStorageKey,
      value: JSON.stringify(info),
    });
  }

  async reset() {
    try {
      const info = await this.getNotificationsInfo();
      if (info.id) {
        await api.delete(info.id);
      }
    } catch (error) {
      // fail silently
    } finally {
      await Preferences.remove({ key: notificationsStorageKey });
    }
  }

  async register(skipInit?: boolean) {
    if (!isPlatform("capacitor")) {
      return false;
    }
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      permStatus = await PushNotifications.requestPermissions();
    }
    if (!(await this.isRegistered())) {
      await PushNotifications.register();
      await Preferences.set({
        key: notificationsRegisteredStorageKey,
        value: "true",
      });
      if (!skipInit) {
        try {
          await this.init();
        } catch (error: any) {
          // not critical, fail silently
          rollbar.error(error);
        }
      }
    }
    if (permStatus.receive !== "granted") {
      return false;
    }
    return true;
  }

  async getToken() {
    return new Promise((resolve) => {
      PushNotifications.addListener("registration", async ({ value }) => {
        let token = value; // Push token for Android

        // Get FCM token instead the APN one returned by Capacitor
        if (Capacitor.getPlatform() === "ios") {
          const { token: fcmToken } = await FCM.getToken();
          token = fcmToken;
        }
        // Work with FCM_TOKEN
        resolve(token);
      });
      PushNotifications.register();
    });
  }

  async enable() {
    const info = await this.getNotificationsInfo();
    const deviceInfo = await Device.getInfo();
    const id = (
      await api.post<{ "@id": string }>("user_devices", {
        deviceType: 0,
        token: await this.getToken(),
        deviceOSVersion: deviceInfo.osVersion,
        deviceOSType: deviceInfo.operatingSystem,
      })
    ).data["@id"];
    const updatedInfo: NotificationsInfo = {
      ...info,
      enabled: true,
      id,
    };
    await Preferences.set({
      key: notificationsStorageKey,
      value: JSON.stringify(updatedInfo),
    });
    return updatedInfo;
  }

  async disable() {
    const info = await this.getNotificationsInfo();
    if (!info.id) {
      throw new Error("Notifications was not enabled");
    }
    await api.delete(info.id);
    const updatedInfo: NotificationsInfo = {
      ...info,
      enabled: false,
      id: undefined,
    };
    await Preferences.set({
      key: notificationsStorageKey,
      value: JSON.stringify(updatedInfo),
    });
    return updatedInfo;
  }

  private async isRegistered() {
    return !!(await Preferences.get({ key: notificationsRegisteredStorageKey }))
      .value;
  }
}

export default new NotificationsService();
