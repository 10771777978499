<template>
  <ion-page>
    <ion-header>
      <div class="loopin-page-heading">
        {{ t("services-page.title") }}
      </div>
      <div class="input-wrap">
        <SearchInput
          :placeholder="t('common.search-services') + '...'"
          :onChange="onSearchQueryChange"
        />
      </div>
    </ion-header>
    <ion-content class="content">
      <ion-refresher
        slot="fixed"
        class="refresher"
        :disabled="isLoading || !!loadError"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <empty-message
        v-if="!!loadError"
        @reload="loadServices"
        :error="{
          error: loadError,
          showRetryButton: true,
        }"
      ></empty-message>
      <template v-else>
        <template v-if="isLoading">
          <ServiceCard
            className="service-card"
            v-for="service in [1, 2]"
            :key="service"
            :isLoading="true"
          />
        </template>
        <template v-else>
          <empty-message v-if="!services.length">{{
            t("common.no-services")
          }}</empty-message>
          <template v-else>
            <ServiceCard
              className="service-card"
              v-for="service in services"
              :key="service['@id']"
              :service="service"
              @click="browserOpen({ url: service.targetUrl })"
            />
            <ion-infinite-scroll
              @ionInfinite="doInfinite($event)"
              threshold="100px"
              :disabled="!nextPage"
            >
              <ion-infinite-scroll-content> </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </template>
        </template>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";
import SearchInput from "@/components/SearchInput.vue";
import ServiceCard from "@/components/ServiceCard.vue";
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/services/api.service";
import { HydraListResponse } from "@/types/api";
import { ThirdPartyService } from "@/types/thirdPartyServices";
import errorHandlerService from "@/services/error-handler.service";
import EmptyMessage from "@/components/EmptyMessage.vue";
import { useI18n } from "vue-i18n";
import { Browser } from "@capacitor/browser";

export default defineComponent({
  name: "ServicesPage",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    SearchInput,
    ServiceCard,
    EmptyMessage,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  setup() {
    const { t } = useI18n();
    const services = ref<ThirdPartyService[]>([]);
    const isLoading = ref<boolean>(true);
    const searchQuery = ref<string>();
    const loadError = ref<any | false>();
    const nextPage = ref<string>();

    const loadServices = async () => {
      if (loadError.value) {
        loadError.value = false;
        isLoading.value = true;
        nextPage.value = undefined;
      }
      try {
        const response = nextPage.value
          ? (
              await apiService.get<HydraListResponse<ThirdPartyService>>(
                nextPage.value
              )
            ).data
          : (
              await apiService.get<HydraListResponse<ThirdPartyService>>(
                "/third_party_services",
                {
                  params: {
                    tags: searchQuery.value?.split(" "),
                    pagination: true,
                    itemsPerPage: 5,
                  },
                }
              )
            ).data;
        services.value = nextPage.value
          ? [...services.value, ...response["hydra:member"]]
          : response["hydra:member"];
        nextPage.value = response["hydra:view"]["hydra:next"];
      } catch (error: any) {
        if (!nextPage.value) {
          loadError.value = error;
        }
        errorHandlerService.handleApiError(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      loadServices();
    });
    const onSearchQueryChange = (query: string) => {
      searchQuery.value = query ? query : undefined;
      if (nextPage.value) {
        nextPage.value = undefined;
      }
      loadServices();
    };
    return {
      t,
      loadError,
      loadServices,
      services,
      isLoading,
      onSearchQueryChange,
      nextPage,
      browserOpen: Browser.open,
      doInfinite: async (event: any) => {
        try {
          await loadServices();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
      doRefresh: async (event: any) => {
        nextPage.value = undefined;
        try {
          await loadServices();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
    };
  },
});
</script>

<style scoped>
.content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 8px;
  --padding-bottom: 50px;
}
.input-wrap {
  padding: 0 20px 12px 20px;
}
.service-card {
  margin-bottom: 20px;
}
</style>