import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fbf7260"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loopin-page-heading" }
const _hoisted_2 = { class: "input-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_empty_message = _resolveComponent("empty-message")!
  const _component_ServiceCard = _resolveComponent("ServiceCard")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t("services-page.title")), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SearchInput, {
              placeholder: _ctx.t('common.search-services') + '...',
              onChange: _ctx.onSearchQueryChange
            }, null, 8, ["placeholder", "onChange"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            class: "refresher",
            disabled: _ctx.isLoading || !!_ctx.loadError,
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }, 8, ["disabled"]),
          (!!_ctx.loadError)
            ? (_openBlock(), _createBlock(_component_empty_message, {
                key: 0,
                onReload: _ctx.loadServices,
                error: {
          error: _ctx.loadError,
          showRetryButton: true,
        }
              }, null, 8, ["onReload", "error"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList([1, 2], (service) => {
                      return _createVNode(_component_ServiceCard, {
                        className: "service-card",
                        key: service,
                        isLoading: true
                      })
                    }), 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (!_ctx.services.length)
                        ? (_openBlock(), _createBlock(_component_empty_message, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("common.no-services")), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
                              return (_openBlock(), _createBlock(_component_ServiceCard, {
                                className: "service-card",
                                key: service['@id'],
                                service: service,
                                onClick: ($event: any) => (_ctx.browserOpen({ url: service.targetUrl }))
                              }, null, 8, ["service", "onClick"]))
                            }), 128)),
                            _createVNode(_component_ion_infinite_scroll, {
                              onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doInfinite($event))),
                              threshold: "100px",
                              disabled: !_ctx.nextPage
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_infinite_scroll_content)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ], 64))
                    ], 64))
              ], 64))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}