import api from "@/services/api.service";
import dealsService from "@/services/deals.service";
import { HydraListResponse } from "@/types/api";
import { Company } from "@/types/company";
import { Calendar, CalendarEvent, Talent } from "@/types/talent";
import { createApiModule, StoreApiData } from "../generic";

export const mutationTypes = {
  reset: "talent/reset",
  updateAvatar: "talent/updateAvatar",
};

export const actionTypes = {
  loadTalent: "talent/loadTalent",
  loadCompany: "talent/loadCompany",
  loadCalendar: "talent/loadCalendar",
  loadCalendarEvents: "talent/loadCalendarEvents",
  loadHasDeals: "talent/loadHasDeals",
};

export type TalentState = {
  talent?: StoreApiData<Talent>;
  company?: StoreApiData<Company>;
  calendar?: StoreApiData<Calendar>;
  calendarEvents?: StoreApiData<CalendarEvent[]>;
  hasDeals?: StoreApiData<boolean>;
};

export default createApiModule<TalentState>(
  "talent",
  ({ baseModule, apiDataActionHelper }) => {
    return {
      actions: {
        async [actionTypes.loadTalent]({ commit, rootState, dispatch }) {
          return apiDataActionHelper("talent", commit, async () => {
            if (!rootState.user.me) {
              await dispatch("user/loadMe");
            }
            if (!rootState.user.me) {
              throw new Error("No user");
            }
            const talentPath = rootState.user.me.talent;
            return (await api.get(talentPath)).data;
          });
        },
        async [actionTypes.loadCompany]({ commit, state, dispatch }) {
          if (!state.talent) {
            await dispatch(actionTypes.loadTalent);
          }
          if (!state.talent?.data?.company) {
            return;
          }
          const company = state.talent.data.company;
          return apiDataActionHelper(
            "company",
            commit,
            async () => (await api.get(company["@id"])).data
          );
        },
        async [actionTypes.loadCalendarEvents]({ commit, state, dispatch }) {
          if (!state.talent) {
            await dispatch(actionTypes.loadTalent);
          }
          if (!state.talent?.data?.calendar) {
            return;
          }
          const calendarUrl = state.talent.data.calendar;
          return apiDataActionHelper(
            "calendarEvents",
            commit,
            async () =>
              (
                await api.get<HydraListResponse<CalendarEvent[]>>(
                  `${calendarUrl}/calendar_events`
                )
              ).data["hydra:member"]
          );
        },
        async [actionTypes.loadCalendar]({ commit, state, dispatch }) {
          if (!state.talent) {
            await dispatch(actionTypes.loadTalent);
          }
          if (!state.talent?.data?.calendar) {
            return;
          }
          const calendarUrl = state.talent.data.calendar;
          return apiDataActionHelper(
            "calendar",
            commit,
            async () => (await api.get<Calendar>(calendarUrl)).data
          );
        },
        async [actionTypes.loadHasDeals]({ commit, state, dispatch }) {
          if (!state.talent) {
            await dispatch(actionTypes.loadTalent);
          }
          return apiDataActionHelper(
            "hasDeals",
            commit,
            async () =>
              (
                await dealsService.fetchDeals({
                  itemsPerPage: 1,
                  pagination: true,
                })
              )["hydra:totalItems"] > 0
          );
        },
      },
      mutations: {
        ...baseModule.mutations,
        [mutationTypes.reset](state) {
          state.talent = undefined;
        },
      },
    };
  }
);
