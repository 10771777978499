import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c96b665"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['search-input', _ctx.className])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_font_awesome_icon, { icon: ['fas', 'search'] })
      ]),
      _createVNode(_component_ion_input, {
        ref: "inputRef",
        modelValue: _ctx.inputValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        onInput: _ctx.inputHandler,
        onIonFocus: _ctx.focusHandler,
        onIonBlur: _ctx.blurHandler,
        class: "input",
        placeholder: _ctx.placeholder
      }, null, 8, ["modelValue", "onInput", "onIonFocus", "onIonBlur", "placeholder"]),
      (_ctx.focused && _ctx.inputValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "clear",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clearHandler && _ctx.clearHandler(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle-xmark'] })
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.focused)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cancel",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancelHandler()))
        }, _toDisplayString(_ctx.t("common.cancel")), 1))
      : _createCommentVNode("", true)
  ]))
}