<template>
  <ion-page>
    <ion-header v-if="hasDeals?.data !== false">
      <div class="loopin-page-heading">
        {{ t("revenue-page.title") }}
      </div>
      <div class="segment-wrap">
        <ion-segment
          class="loopin-segment"
          v-model="selectedRange"
          @ionChange="onRangeChange"
        >
          <ion-segment-button
            v-for="option in rangeOptions"
            class="loopin-segment-button"
            :value="option.value"
            :key="option.value"
          >
            <ion-label>{{ option.label }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </div>
    </ion-header>

    <ion-content>
      <ion-refresher
        slot="fixed"
        class="refresher"
        :disabled="isLoading || hasDeals?.loading"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content> </ion-refresher-content>
      </ion-refresher>
      <empty-message
        v-if="!!loadError"
        @reload="loadData"
        :error="{
          error: loadError,
          showRetryButton: true,
        }"
      ></empty-message>
      <template v-else>
        <no-deals-placeholder v-if="hasDeals?.data === false" />
        <template v-else>
          <div class="chart-header">
            <div class="label">
              {{ t("revenue-page.gross-volume") }}
            </div>
            <div class="count">
              <ion-skeleton-text
                style="height: 26px; width: 70px"
                v-if="isLoading || hasDeals?.isLoading"
                animated
              />
              <template v-else>
                {{
                  lineChartInfo.hoverTitle
                    ? lineChartInfo.hoverTitle
                    : lineChartInfo.title
                }}
              </template>
            </div>
            <div class="subtitle">
              <ion-skeleton-text
                style="height: 18px; width: 45px"
                v-if="isLoading || hasDeals?.isLoading"
                animated
              />
              <template v-else>
                {{
                  lineChartInfo.hoverSubTitle
                    ? lineChartInfo.hoverSubTitle
                    : lineChartInfo.subTitle
                }}
              </template>
            </div>
          </div>
          <div class="chart-content">
            <div
              class="chart-wrap"
              v-if="lineChartDataSet && !(isLoading || hasDeals?.isLoading)"
            >
              <line-chart
                :dataSet="lineChartDataSet"
                @hover="onLineChartHoverHandler"
                @hoverEnd="resetHoverValues"
              ></line-chart>
            </div>
            <empty-message
              :className="'chart-empty-message'"
              :isLoading="true"
              v-else
            />
            <div class="chart-header">
              <div class="label">
                {{ t("revenue-page.deals-count") }}
              </div>
              <div class="count">
                <ion-skeleton-text
                  style="height: 26px; width: 70px"
                  v-if="isLoading || hasDeals?.isLoading"
                  animated
                />
                <template v-else>
                  {{
                    barChartInfo.hoverTitle
                      ? barChartInfo.hoverTitle
                      : barChartInfo.title
                  }}
                </template>
              </div>
              <div class="subtitle">
                <ion-skeleton-text
                  style="height: 18px; width: 45px"
                  v-if="isLoading || hasDeals?.isLoading"
                  animated
                />
                <template v-else>
                  {{
                    barChartInfo.hoverSubTitle
                      ? barChartInfo.hoverSubTitle
                      : barChartInfo.subTitle
                  }}
                </template>
              </div>
            </div>
            <div
              class="chart-wrap"
              v-if="barChartDataSet && !(isLoading || hasDeals?.isLoading)"
            >
              <bar-chart
                v-if="barChartDataSet"
                :dataSet="barChartDataSet"
                @hover="onBarChartHoverHandler"
                @hoverEnd="resetHoverValues"
              ></bar-chart>
            </div>
            <empty-message
              :className="'chart-empty-message'"
              :isLoading="true"
              v-else
            />
          </div>
        </template>
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import LineChart from "@/components/LineChart.vue";
import BarChart from "@/components/BarChart.vue";
import EmptyMessage from "@/components/EmptyMessage.vue";
import errorHandlerService from "@/services/error-handler.service";
import { DateTime } from "luxon";
import { formatPrice } from "@/utils";
import { useUserTalentHasDeals } from "@/hooks/talent";
import NoDealsPlaceholder from "@/components/NoDealsPlaceholder.vue";
import { useI18n } from "vue-i18n";
import userService from "@/services/user.service";
import localeService from "@/services/locale.service";

type ChartInfo = {
  title: string;
  subTitle: string;
  hoverTitle?: string;
  hoverSubTitle?: string;
};

export default defineComponent({
  name: "RevenuePage",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    LineChart,
    BarChart,
    EmptyMessage,
    IonSkeletonText,
    NoDealsPlaceholder,
    IonRefresher,
    IonRefresherContent,
  },
  setup() {
    const selectedRange = ref<"6" | "12" | "All">("6");
    const isLoading = ref<boolean>(true);
    const lineChartDataSet = ref<{ labels: string[]; data: number[] }>();
    const barChartDataSet = ref<{ labels: string[]; data: number[] }>();
    const lineChartInfo = ref<ChartInfo>({ title: "", subTitle: "" });
    const barChartInfo = ref<ChartInfo>({ title: "", subTitle: "" });
    const loadError = ref<any | false>();
    const { t, locale } = useI18n();

    const loadData = async () => {
      try {
        if (loadError.value) {
          loadError.value = false;
          isLoading.value = true;
        }
        let minMonths = 6;
        let startDate = undefined;
        if (selectedRange.value !== "All") {
          minMonths = parseInt(selectedRange.value);
          startDate = DateTime.now()
            .minus({
              months: parseInt(selectedRange.value),
            })
            .startOf("month")
            .toISODate();
        }
        const insights = await userService.getInsights({
          startDate,
          endDate: DateTime.now().endOf("month").toISODate(),
        });
        const series = insights.series?.["hydra:member"] || [];
        const lastSeriesDate = series.length
          ? DateTime.fromFormat(
              series[series.length - 1].formattedDate,
              "yyyy-MM"
            )
          : null;
        let months = lastSeriesDate
          ? Math.round(
              DateTime.now().endOf("month").diff(lastSeriesDate, ["months"])
                .months
            )
          : minMonths;
        if (months < minMonths) {
          months = minMonths;
        }

        const lineChartData: { labels: string[]; data: number[] } = {
          labels: [],
          data: [],
        };
        const barChartData: { labels: string[]; data: number[] } = {
          labels: [],
          data: [],
        };

        let currentMonth = months;
        while (currentMonth !== 0) {
          const date = DateTime.now()
            .setLocale(localeService.selectedLocale)
            .minus({ months: months - currentMonth });
          const month = date.toFormat("yyyy-MM");
          const seriesItem = series.find(
            (item) => item.formattedDate === month
          );
          currentMonth -= 1;

          const label = date.toFormat("LLL");
          lineChartData.labels.unshift(label);
          barChartData.labels.unshift(label);
          lineChartData.data.unshift(seriesItem?.totalInvoicedPaidWithTax || 0);
          barChartData.data.unshift(seriesItem?.totalDeal || 0);
        }

        const subTitle = `${lineChartData.labels[0]}-${
          lineChartData.labels[lineChartData.labels.length - 1]
        }`;

        lineChartDataSet.value = lineChartData;
        barChartDataSet.value = barChartData;

        lineChartInfo.value = {
          ...lineChartInfo.value,
          title: formatPrice(insights.totalInvoicedPaidWithTax),
          subTitle,
        };
        barChartInfo.value = {
          ...barChartInfo.value,
          title: insights.dealsCount.toString(),
          subTitle,
        };
      } catch (error: any) {
        loadError.value = error;
        errorHandlerService.handleApiError(error);
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(() => {
      loadData();
    });
    const instance = getCurrentInstance();
    watch(locale, async () => {
      await loadData();
      instance?.proxy?.$forceUpdate();
    });
    const hasDeals = useUserTalentHasDeals();
    return {
      t,
      loadError,
      loadData,
      hasDeals,
      isLoading,
      lineChartDataSet,
      barChartDataSet,
      lineChartInfo,
      barChartInfo,
      rangeOptions: [
        {
          value: "6",
          label: "6m",
        },
        {
          value: "12",
          label: "1y",
        },
        /* {
          value: "All",
          label: "All",
        }, */
      ],
      selectedRange,
      onRangeChange: () => {
        loadData();
      },
      onLineChartHoverHandler: ({
        value,
        label,
      }: {
        value: number;
        label: string;
      }) => {
        lineChartInfo.value = {
          ...lineChartInfo.value,
          hoverTitle: formatPrice(value),
          hoverSubTitle: label,
        };
      },
      onBarChartHoverHandler: ({
        value,
        label,
      }: {
        value: number;
        label: string;
      }) => {
        barChartInfo.value = {
          ...barChartInfo.value,
          hoverTitle: value.toString(),
          hoverSubTitle: label,
        };
      },
      resetHoverValues: () => {
        barChartInfo.value = {
          ...barChartInfo.value,
          hoverTitle: undefined,
          hoverSubTitle: undefined,
        };
        lineChartInfo.value = {
          ...lineChartInfo.value,
          hoverTitle: undefined,
          hoverSubTitle: undefined,
        };
      },

      doRefresh: async (event: any) => {
        try {
          await loadData();
        } finally {
          if (event?.target) {
            (event.target as any).complete();
          }
        }
      },
    };
  },
});
</script>

<style scoped>
.segment-wrap {
  margin: 0 20px;
}
.chart-header {
  padding: 20px 20px 10px 20px;
}
.label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
.count {
  font-size: 21px;
  line-height: 26px;
  font-weight: bold;
}
.chart-wrap {
  padding: 0 20px 0 10px;
}
.subtitle {
  font-size: 14px;
  color: var(--loopin-color-gs-70);
  margin-top: 5px;
}
.chart-content {
  padding-bottom: 100px;
}
.chart-empty-message {
  height: 225px;
}
</style>