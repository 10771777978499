<template>
  <ion-page>
    <ion-content>
      <swiper
        class="loopin-swiper loopin-swiper--onboarding"
        @swiper="setSwiperInstance"
        :modules="swiperModules"
        :pagination="true"
      >
        <swiper-slide v-for="slide of slidesInfo" :key="slide.id" class="slide">
          <div class="slide-wrap">
            <img :src="slide.image" />
            <div class="slide-text">
              <div class="title">
                {{ slide.title }}
              </div>
              <div class="subtitle">
                {{ slide.subtitle }}
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <Button className="button" @click="goNext">
        <template
          v-if="slides && slides.activeIndex === slides.slides.length - 1"
        >
          {{ t("onboarding-page.lets-talk-button") }}
        </template>
        <template v-else> {{ t("common.continue") }} </template>
      </Button>
      <Button className="button" :fill="'outline'" @click="goToLogin">
        {{ t("onboarding-page.already-have-account-button") }}
      </Button>
    </ion-content>
  </ion-page>
</template>
<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { arrowForward } from "ionicons/icons";
import { Pagination, Swiper as SwiperType } from "swiper";
import { useRouter } from "vue-router";
import Button from "@/components/Button.vue";

import "swiper/css";
import "swiper/css/pagination";
import "@ionic/vue/css/ionic-swiper.css";
import { Browser } from "@capacitor/browser";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "OnboardingPage",
  components: {
    IonContent,
    IonPage,
    Swiper,
    SwiperSlide,
    Button,
  },
  methods: {
    goToLogin() {
      this.router.replace("/login");
    },
    goNext() {
      if (!this.slides) {
        return;
      }
      if (this.slides.activeIndex === this.slides.slides.length - 1) {
        Browser.open({ url: "https://www.loopin.ai/creator" });
      } else {
        this.slides.slideNext();
      }
    },
  },
  setup() {
    const { t } = useI18n();
    const slides = ref<SwiperType>();
    const setSwiperInstance = (swiper: SwiperType) => {
      slides.value = swiper;
    };
    const router = useRouter();
    return {
      t,
      swiperModules: [Pagination],
      setSwiperInstance,
      slides,
      router,
      icons: {
        arrowForward,
      },
      slidesInfo: [
        {
          id: 1,
          image: require("@/assets/images/onboarding-1.png"),
          title: t("onboarding-page.step-1-title"),
          subtitle: t("onboarding-page.step-1-description"),
        },
        {
          id: 2,
          image: require("@/assets/images/onboarding-2.png"),
          title: t("onboarding-page.step-2-title"),
          subtitle: t("onboarding-page.step-2-description"),
        },
        {
          id: 3,
          image: require("@/assets/images/onboarding-3.png"),
          title: t("onboarding-page.step-3-title"),
          subtitle: t("onboarding-page.step-3-description"),
        },
        {
          id: 4,
          image: require("@/assets/images/onboarding-4.png"),
          title: t("onboarding-page.step-4-title"),
          subtitle: t("onboarding-page.step-4-description"),
        },
        {
          id: 5,
          image: require("@/assets/images/onboarding-5.png"),
          title: t("onboarding-page.step-5-title"),
          subtitle: t("onboarding-page.step-5-description"),
        },
        {
          id: 6,
          image: require("@/assets/images/onboarding-6.png"),
          title: t("onboarding-page.step-6-title"),
          subtitle: t("onboarding-page.step-6-description"),
        },
        {
          id: 7,
          image: require("@/assets/images/onboarding-7.png"),
          title: t("onboarding-page.step-7-title"),
          subtitle: t("onboarding-page.step-7-description"),
        },
      ],
    };
  },
});
</script>

<style scoped>
ion-content {
  --overflow: hidden;
}
.button {
  margin: 10px 40px;
}
.loopin-swiper {
  height: 70vh;
}
.slide-wrap {
  padding: 0 26px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.slide-wrap img {
  width: 100%;
  margin: 0 auto;
  max-width: 320px;
  margin-bottom: 30px;
}
.slide-text {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.title {
  font-size: 32px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 18px;
}
.subtitle {
  font-size: 20px;
  line-height: 22px;
  color: var(--loopin-color-gs-60);
}
</style>
