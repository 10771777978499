<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="loopin-toolbar">
        <ion-title>{{ t("settings-page.title") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="list" lines="none">
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/personal-profile"
        >
          {{ t("personal-profile-page.title") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/company-profile"
        >
          {{ t("company-profile-page.title") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/assistants"
        >
          {{ t("assistants-page.title") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          @click="handleSendInvoicesClick"
        >
          {{ t("settings-page.send-invoices") }}
        </ion-item>
      </ion-list>

      <ion-list class="list" lines="none">
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/external-accounts/social"
        >
          {{ t("external-accounts-page.title") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/external-accounts/calendar"
        >
          {{ t("common.calendar") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
      </ion-list>

      <ion-list class="list" lines="none">
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/notifications"
        >
          {{ t("common.notifications") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          router-link="/tabs/settings/language"
        >
          {{ t("language-settings-page.title") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
      </ion-list>

      <ion-list class="list" lines="none">
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          @click="emailSupport"
        >
          {{ t("settings-page.contact-support") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          @click="browserOpen({ url: 'https://www.loopin.ai/terms' })"
        >
          {{ t("settings-page.terms") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
        <ion-item
          class="item"
          :button="true"
          :detail="false"
          @click="browserOpen({ url: 'https://www.loopin.ai/privacy' })"
        >
          {{ t("settings-page.privacy") }}
          <font-awesome-icon
            class="item__icon"
            slot="end"
            :icon="['fas', 'chevron-right']"
          />
        </ion-item>
      </ion-list>
      <div class="logout-btn-wrap">
        <ion-button
          @click="logout"
          color="dark"
          class="logout-btn"
          expand="block"
          fill="clear"
        >
          <font-awesome-icon
            class="logout-btn-icon"
            slot="start"
            :icon="['fas', 'sign-out']"
          />
          {{ t("settings-page.logout") }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonButton,
  useIonRouter,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { actionTypes, storeKey } from "@/store";
import errorHandlerService from "@/services/error-handler.service";
import SendInvoicesModal from "@/modals/SendInvoices.vue";
import { useI18n } from "vue-i18n";
import { Browser } from "@capacitor/browser";
import { EmailComposer } from "capacitor-email-composer";

export default defineComponent({
  name: "SettingsPage",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonButton,
  },
  setup() {
    const router = useIonRouter();
    const store = useStore(storeKey);
    const { t } = useI18n();

    return {
      t,
      browserOpen: Browser.open,
      logout: async () => {
        await store.dispatch(actionTypes.user.logout);
        router.navigate("/", "root", "replace");
      },
      emailSupport: async () => {
        if (!(await EmailComposer.hasAccount()).hasAccount) {
          return errorHandlerService.handleError({
            message: t("error-messages.email-composer-not-setup"),
          });
        }
        EmailComposer.open({
          to: ["support@loopin.ai"],
        });
      },
      handleSendInvoicesClick: async () => {
        const modal = await modalController.create({
          component: SendInvoicesModal,
          handle: false,
          cssClass: "loopin-modal",
        });
        modal.present();
      },
      router,
    };
  },
});
</script>

<style scoped>
.list {
  margin: 16px 20px;
  border-radius: 16px;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.item {
  --background: var(--loopin-item-background);
  --min-height: 58px;
}
.item__icon {
  font-size: 16px;
}
.item__right-text {
  font-size: 14px;
  line-height: 24px;
  color: var(--loopin-color-gs-70);
}
.logout-btn-icon {
  margin-right: 12px;
}
.logout-btn-wrap {
  padding-bottom: 50px;
}
</style>