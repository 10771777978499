<template>
  <ion-page>
    <ion-tabs ref="tabs">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar class="tabbar">
        <ion-tab-button tab="home" href="/tabs/home">
          <font-awesome-icon class="tabbar__icon" :icon="['fas', 'house']" />
          <ion-label>{{ t("common.home") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="deals" href="/tabs/deals">
          <font-awesome-icon
            class="tabbar__icon"
            :icon="['fas', 'champagne-glasses']"
          />
          <ion-label>{{ t("common.deal", 2) }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="revenue" href="/tabs/revenue">
          <font-awesome-icon
            class="tabbar__icon"
            :icon="['fas', 'chart-line']"
          />
          <ion-label>{{ t("common.revenue") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="services" href="/tabs/services">
          <font-awesome-icon
            class="tabbar__icon"
            :icon="['fas', 'bell-concierge']"
          />
          <ion-label>{{ t("common.services", 2) }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="settings" href="/tabs/settings">
          <font-awesome-icon class="tabbar__icon" :icon="['fas', 'gear']" />
          <ion-label>{{ t("common.settings") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonRouterOutlet,
  IonLabel,
} from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default {
  name: "TabsPage",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRouterOutlet,
    IonLabel,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped>
.tabbar {
  --background: var(--loopin-tab-bar-background);
  height: 100px;
}
.tabbar ion-tab-button {
  --color-selected: var(--loopin-color-gs-100);
  --color: var(--loopin-color-gs-40);
}
.tabbar ion-label {
  font-size: 11px;
  line-height: 24px;
}
.tabbar__icon {
  font-size: 24px;
}
</style>