import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineChart = _resolveComponent("LineChart", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LineChart, {
      ref: "lineRef",
      height: 250,
      chartData: _ctx.chartData,
      options: _ctx.options,
      plugins: _ctx.plugins
    }, null, 8, ["chartData", "options", "plugins"])
  ]))
}