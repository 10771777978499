<template>
  <div :class="['card', className, 'ion-activatable']" @click="onClick">
    <template v-if="isLoading">
      <div class="card__image">
        <ion-skeleton-text
          style="width: 46px; height: 46px; margin: 0"
          animated
        />
      </div>
      <div class="card__content">
        <div class="card__title">
          <ion-skeleton-text style="width: 120px; height: 16px" animated />
        </div>
        <div class="card__sub-title">
          <ion-skeleton-text style="width: 80px; height: 12px" animated />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="card__image" v-if="image || defaultImage">
        <img v-if="image" :src="image" alt="Image" />
        <div v-else class="card__image-placeholder">
          <font-awesome-icon
            class="card__image-placeholder-icon"
            :icon="['fas', 'image']"
          />
        </div>
      </div>
      <div class="card__content">
        <div class="card__title" v-if="title">
          {{ title }}
        </div>
        <div class="card__sub-title" v-if="subTitle">
          {{ subTitle }}
        </div>
      </div>
      <div class="card__right">
        <div
          v-if="onDescriptionClick"
          @click.stop="onDescriptionClick"
          class="card__right-click-overlay"
        ></div>
        <Badge v-if="badge" :color="badge.color" :className="badge.className">
          {{ badge.text }}
        </Badge>
        <div v-if="rightTitle" class="card__right-title">
          {{ rightTitle }}
        </div>
        <div v-if="rightDescription" class="card__right-description">
          {{ rightDescription }}
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { AppColor } from "@/types";
import { defineComponent, PropType } from "vue";
import { IonSkeletonText } from "@ionic/vue";
import Badge from "@/components/Badge.vue";

type BadgeProps = {
  text: string;
  color?: AppColor;
  className?: string;
};
export default defineComponent({
  name: "list-card",
  components: {
    Badge,
    IonSkeletonText,
  },
  props: {
    className: String,
    title: String,
    subTitle: String,
    badge: Object as PropType<BadgeProps>,
    image: String,
    rightTitle: String,
    rightDescription: String,
    onClick: {
      type: Function as PropType<(e: MouseEvent) => void>,
    },
    onDescriptionClick: {
      type: Function as PropType<(e: MouseEvent) => void>,
    },
    isLoading: Boolean,
    defaultImage: Boolean,
  },
});
</script>

<style scoped>
.card {
  background: var(--loopin-item-background);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 82px;
  transform: translate3d(0, 0, 0);
}
.card__image {
  width: 46px;
  height: 46px;
  margin-right: 12px;
  border-radius: 5px;
  overflow: hidden;
}
.card__content {
}
.card__image-placeholder {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__image-placeholder-icon {
  font-size: 24px;
  color: var(--loopin-color-gs-40);
}
.card__title {
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
}
.card__sub-title {
  color: var(--loopin-color-gs-60);
  line-height: 16px;
  font-size: 13px;
  margin-top: 4px;
}
.card__right {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}
.card__right-click-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.card__right-title {
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
}
.card__right-description {
  color: var(--loopin-color-gs-60);
  line-height: 16px;
  font-size: 13px;
  margin-top: 4px;
  text-align: right;
  white-space: nowrap;
}
</style>