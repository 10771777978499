import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bdc38fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loopin-page-heading" }
const _hoisted_2 = { class: "segment-wrap" }
const _hoisted_3 = { class: "chart-header" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "count" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = { class: "chart-content" }
const _hoisted_8 = {
  key: 0,
  class: "chart-wrap"
}
const _hoisted_9 = { class: "chart-header" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "count" }
const _hoisted_12 = { class: "subtitle" }
const _hoisted_13 = {
  key: 2,
  class: "chart-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_empty_message = _resolveComponent("empty-message")!
  const _component_no_deals_placeholder = _resolveComponent("no-deals-placeholder")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_bar_chart = _resolveComponent("bar-chart")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.hasDeals?.data !== false)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t("revenue-page.title")), 1),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_segment, {
                  class: "loopin-segment",
                  modelValue: _ctx.selectedRange,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRange) = $event)),
                  onIonChange: _ctx.onRangeChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rangeOptions, (option) => {
                      return (_openBlock(), _createBlock(_component_ion_segment_button, {
                        class: "loopin-segment-button",
                        value: option.value,
                        key: option.value
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.label), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onIonChange"])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            class: "refresher",
            disabled: _ctx.isLoading || _ctx.hasDeals?.loading,
            onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }, 8, ["disabled"]),
          (!!_ctx.loadError)
            ? (_openBlock(), _createBlock(_component_empty_message, {
                key: 0,
                onReload: _ctx.loadData,
                error: {
          error: _ctx.loadError,
          showRetryButton: true,
        }
              }, null, 8, ["onReload", "error"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.hasDeals?.data === false)
                  ? (_openBlock(), _createBlock(_component_no_deals_placeholder, { key: 0 }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("revenue-page.gross-volume")), 1),
                        _createElementVNode("div", _hoisted_5, [
                          (_ctx.isLoading || _ctx.hasDeals?.isLoading)
                            ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                key: 0,
                                style: {"height":"26px","width":"70px"},
                                animated: ""
                              }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.lineChartInfo.hoverTitle
                    ? _ctx.lineChartInfo.hoverTitle
                    : _ctx.lineChartInfo.title), 1)
                              ], 64))
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          (_ctx.isLoading || _ctx.hasDeals?.isLoading)
                            ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                key: 0,
                                style: {"height":"18px","width":"45px"},
                                animated: ""
                              }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.lineChartInfo.hoverSubTitle
                    ? _ctx.lineChartInfo.hoverSubTitle
                    : _ctx.lineChartInfo.subTitle), 1)
                              ], 64))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        (_ctx.lineChartDataSet && !(_ctx.isLoading || _ctx.hasDeals?.isLoading))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_line_chart, {
                                dataSet: _ctx.lineChartDataSet,
                                onHover: _ctx.onLineChartHoverHandler,
                                onHoverEnd: _ctx.resetHoverValues
                              }, null, 8, ["dataSet", "onHover", "onHoverEnd"])
                            ]))
                          : (_openBlock(), _createBlock(_component_empty_message, {
                              key: 1,
                              className: 'chart-empty-message',
                              isLoading: true
                            })),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t("revenue-page.deals-count")), 1),
                          _createElementVNode("div", _hoisted_11, [
                            (_ctx.isLoading || _ctx.hasDeals?.isLoading)
                              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                  key: 0,
                                  style: {"height":"26px","width":"70px"},
                                  animated: ""
                                }))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(_ctx.barChartInfo.hoverTitle
                      ? _ctx.barChartInfo.hoverTitle
                      : _ctx.barChartInfo.title), 1)
                                ], 64))
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            (_ctx.isLoading || _ctx.hasDeals?.isLoading)
                              ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                                  key: 0,
                                  style: {"height":"18px","width":"45px"},
                                  animated: ""
                                }))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(_ctx.barChartInfo.hoverSubTitle
                      ? _ctx.barChartInfo.hoverSubTitle
                      : _ctx.barChartInfo.subTitle), 1)
                                ], 64))
                          ])
                        ]),
                        (_ctx.barChartDataSet && !(_ctx.isLoading || _ctx.hasDeals?.isLoading))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              (_ctx.barChartDataSet)
                                ? (_openBlock(), _createBlock(_component_bar_chart, {
                                    key: 0,
                                    dataSet: _ctx.barChartDataSet,
                                    onHover: _ctx.onBarChartHoverHandler,
                                    onHoverEnd: _ctx.resetHoverValues
                                  }, null, 8, ["dataSet", "onHover", "onHoverEnd"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (_openBlock(), _createBlock(_component_empty_message, {
                              key: 3,
                              className: 'chart-empty-message',
                              isLoading: true
                            }))
                      ])
                    ], 64))
              ], 64))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}