import { toastController } from "@ionic/vue";
import { alertCircle } from "ionicons/icons";
import { AxiosError } from "axios";
import { rollbar } from "@/main";
import localeService from "./locale.service";

class ErrirHandlerService {
  async handleError(
    params?: { message?: string; header?: string },
    error?: any
  ) {
    console.error(error);
    if (error) {
      rollbar.error(error);
    }
    const header = params?.header || localeService.t("common.error");
    const message = params?.message || localeService.t("common.error-message");
    const toast = await toastController.create({
      header,
      message,
      icon: alertCircle,
      position: "top",
      duration: 2000,
      color: "danger",
      buttons: [
        {
          text: localeService.t("common.close"),
          role: "cancel",
        },
      ],
    });
    await toast.present();
  }

  async handleApiError(error?: AxiosError) {
    let message = "";
    if (
      error?.response?.data &&
      (error?.response?.data["hydra:description"] ||
        error?.response?.data?.message)
    ) {
      message =
        error?.response?.data["hydra:description"] ||
        error?.response?.data?.message;
    }
    this.handleError({ message }, error);
  }
}

export default new ErrirHandlerService();
