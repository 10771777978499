import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import talentStore, {
  TalentState,
  actionTypes as talentActionTypes,
  mutationTypes as talentMutationTypes,
} from "./modules/talent";
import userStore, {
  UserState,
  actionTypes as userActionTypes,
  mutationTypes as userMutationTypes,
} from "./modules/user";

export type RootState = {
  user: UserState;
  talent: TalentState;
};

export const actionTypes = {
  user: userActionTypes,
  talent: talentActionTypes,
};

export const mutationTypes = {
  user: userMutationTypes,
  talent: talentMutationTypes,
};

export const store = createStore({
  modules: {
    user: userStore,
    talent: talentStore,
  },
});

export const storeKey: InjectionKey<Store<RootState>> = Symbol();
