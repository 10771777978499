import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c497edf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        fullscreen: true,
        class: "content"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: require('@/assets/images/newsletter-3d.png')
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("login-link-sent-page.title")), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("login-link-sent-page.subtitle")), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}