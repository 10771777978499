import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ea52ceb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slide-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "slide-text" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_swiper, {
            class: "loopin-swiper loopin-swiper--onboarding",
            onSwiper: _ctx.setSwiperInstance,
            modules: _ctx.swiperModules,
            pagination: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slidesInfo, (slide) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: slide.id,
                  class: "slide"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("img", {
                        src: slide.image
                      }, null, 8, _hoisted_2),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(slide.title), 1),
                        _createElementVNode("div", _hoisted_5, _toDisplayString(slide.subtitle), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["onSwiper", "modules"]),
          _createVNode(_component_Button, {
            className: "button",
            onClick: _ctx.goNext
          }, {
            default: _withCtx(() => [
              (_ctx.slides && _ctx.slides.activeIndex === _ctx.slides.slides.length - 1)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.t("onboarding-page.lets-talk-button")), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.t("common.continue")), 1)
                  ], 64))
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_Button, {
            className: "button",
            fill: 'outline',
            onClick: _ctx.goToLogin
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("onboarding-page.already-have-account-button")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}