<template>
  <div :class="['badge', 'badge--color-' + color, className]">
    <slot />
  </div>
</template>

<script lang="ts">
import { AppColor } from "@/types";
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "card-badge",
  components: {},
  props: {
    color: {
      type: String as PropType<AppColor>,
      default: AppColor.red,
    },
    className: {
      type: String,
      required: false,
    },
    onClick: {
      type: Function as PropType<(e: MouseEvent) => void>,
    },
  },
});
</script>

<style scoped>
.badge {
  height: 24px;
  min-width: 62px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  padding: 0 6px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.badge--color-red {
  color: var(--loopin-color-red);
  background: rgba(var(--loopin-color-red-rgb), 0.25);
}
.badge--color-pink {
  color: var(--loopin-color-pink);
  background: rgba(var(--loopin-color-pink-rgb), 0.25);
}
.badge--color-orange {
  color: var(--loopin-color-orange);
  background: rgba(var(--loopin-color-orange-rgb), 0.25);
}
.badge--color-green {
  color: var(--loopin-color-green);
  background: rgba(var(--loopin-color-green-rgb), 0.25);
}
.badge--color-blue {
  color: var(--loopin-color-blue);
  background: rgba(var(--loopin-color-blue-rgb), 0.25);
}
.badge--color-yellow {
  color: var(--loopin-color-yellow);
  background: rgba(var(--loopin-color-yellow-rgb), 0.25);
}
.badge--color-gray {
  color: var(--loopin-color-gray);
  background: rgba(var(--loopin-color-gray-rgb), 0.25);
}
</style>